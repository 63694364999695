.login_step_one_wrapper {
  background-color: #fafafd;
  .login_step_one_inner_wrapper {
    height: calc(100vh - 92px);
    display: flex;
    align-items: center;
    justify-content: center;
    .content_wrapper {
      .content_inner_wrapper {
        width: 580px;
        border-radius: 8px;
        border: solid 1px #f2f2f3;
        background-color: #ffffff;
        padding: 60px;
        .image_container {
          text-align: center;
          img {
          }
        }
        .text_content_wrapper {
          margin: 10px 0;
          .text_content {
            font-size: 32px;
            font-weight: bold;
            line-height: 1.25;
            text-align: center;
            color: #a02b2d;
          }
        }
        .input_wrapper {
          margin: 10px 0;
          input[type="text"] {
            width: 100%;
            height: 50px;
            border-radius: 25px;
            border: solid 1px #f2f2f3;
            background-color: #faf9fe;
            padding: 0 10px;
            font-size: 20px;
            font-weight: 600;
            ::placeholder {
              font-size: 16px;
              font-weight: 500;
              line-height: 1.5;
            }
          }
        }
        .btn_wrapper {
          margin: 20px 0;
          text-align: center;
          button {
            border: none;
            outline: none;
            width: 100%;
            height: 50px;
            border-radius: 25px;
            background-color: #ffffff;
            box-shadow: 0px 7px 30px #6d48ef1c;
            font-size: 18px;
            font-weight: 600;
            color: #000000;
          }
          button:focus {
            background-color: #6d48ef;
            box-shadow: none;
            color: #ffffff;
          }
        }
      }
    }
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .login_step_one_wrapper {
    .login_step_one_inner_wrapper {
      height: calc(100vh - 58px);
      .content_wrapper {
        width: 100%;
        .content_inner_wrapper {
          width: 100%;
          padding: 30px 15px;
          .image_container {
            img {
            }
          }
          .text_content_wrapper {
            .text_content {
            }
          }
          .input_wrapper {
            input[type="text"] {
              ::placeholder {
              }
            }
          }
          .btn_wrapper {
            button {
            }
          }
        }
      }
    }
  }
}
