.userCreateDoubtContentComponent_wrapper {
  padding-top: 80px;
  .userCreateDoubtContentComponent_inner_wrapper {
    padding: 20px;
    .sec_1_wrapper {
      margin: 20px 0;
      .sec_1_inner_wrapper {
        display: flex;
        justify-content: flex-start;
        .btn_wrapper {
          button {
            outline: none;
            border: none;
            background: transparent;
          }
        }
        .text_content_wrapper {
          margin: 0 10px;
          .text_content {
            margin: 0;
            font-size: 20px;
            font-weight: 600;
            color: #0f0404;
          }
        }
      }
    }
    .sec_2_wrapper {
      margin: 20px 0;
      background-color: #ffffff;
      border-radius: 10px;
      padding: 20px;
      .sec_2_inner_wrapper {
        .content_wrapper {
          display: flex;
          justify-content: flex-start;
          .content_inner_wrapper {
            flex: 0 0 100%;
            .sub_content_wrapper_1 {
              margin: 10px 0;
              .select_wrapper {
                select {
                  border-radius: 25px;
                  border: solid 1px #f0eef8;
                  background-color: #fafafd;
                  width: 400px;
                  height: 45px;
                  padding: 0 5px;
                  font-size: 18px;
                  font-weight: 600;
                }
              }
            }
            .sub_content_wrapper_2 {
              margin: 10px 0;
              .textarea_wrapper {
                textarea {
                  border-radius: 10px;
                  border: solid 1px #f0eef8;
                  background-color: #fafafd;
                  width: 100%;
                  padding: 0 5px;
                  font-size: 18px;
                  font-weight: 600;
                }
              }
            }
            .sub_content_wrapper_3 {
              margin: 10px 0;
              .show_uploading_img_wrapper {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;
                .image_wrapper {
                  margin: 10px;
                  img {
                    width: 150px;
                    height: 150px;
                  }
                }
              }
              .input_wrapper {
                position: relative;
                height: 100px;
                .custom_file_upload {
                  position: absolute;
                  left: 0;
                  bottom: 0;
                  cursor: pointer;
                  input[type="file"] {
                    display: none;
                  }
                  .upload_content_action_wrapper {
                    border: 2px dotted #f2f2f3;
                    border-radius: 10px;
                    padding: 5px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    .image_wrapper {
                      img {
                      }
                    }
                    .textual_content_wrapper {
                      margin-left: 5px;
                      .text_content_wrapper_1 {
                        .text_content_1 {
                          margin: 0;
                          font-size: 18px;
                          font-weight: 500;
                          color: #6d48ef;
                        }
                      }
                      .text_content_wrapper_2 {
                        .text_content_2 {
                          margin: 0;
                          font-size: 13px;
                          color: #b5b3ba;
                        }
                      }
                    }
                  }
                }
              }
            }
            .sub_content_wrapper_4 {
              margin: 20px 0;
              .btn_wrapper {
                button {
                  border: none;
                  outline: none;
                  width: 150px;
                  height: 50px;
                  border-radius: 25px;
                  background-color: #6d48ef;
                  font-size: 18px;
                  font-weight: 600;
                  color: #ffffff;
                }
              }
            }
          }
        }
      }
    }
  }
}
