////////No need to subtract scrollbar width in ipad,and mobile screens
.header_component_wrapper {
  width: calc(100vw - 220px - 20px);
  position: fixed;
  z-index: 999;
  .header_inner_wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 10px;
    background-color: #ffffff;
    .header_left_wrapper {
      flex: 0 0 58%;
    }
    .header_right_wrapper {
      flex: 0 0 42%;
    }
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .header_component_wrapper {
    width: calc(100vw - 190px);
    .header_inner_wrapper {
      padding: 10px 10px;
      .header_left_wrapper {
        flex: 0 0 50%;
      }
      .header_right_wrapper {
        flex: 0 0 50%;
      }
    }
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .header_component_wrapper {
    width: calc(100vw - 160px);
    .header_inner_wrapper {
      padding: 15px 5px;

      .header_left_wrapper {
        flex: 0 0 47%;
      }
      .header_right_wrapper {
        flex: 0 0 53%;
      }
    }
  }
}
