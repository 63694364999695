#zmmtg-root {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  display: none;
  z-index: 9999;
  overflow: auto;
}

.meeting-info-container{
  display: none ! important;
}

.scheduleClass_wrapper {
  .scheduleClass_inner_wrapper {
    border-radius: 8px;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.04);
    background-color: #ffffff;
    padding: 20px;
    .sec_1_wrapper {
      margin-bottom: 10px;
      .text_content_wrapper {
        .text_content {
          margin: 0;
          font-size: 20px;
          font-weight: 600;
          color: #0f0404;
        }
      }
    }
    .sec_2_wrapper {
      margin: 10px 0;
      .date_time_collection {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        overflow-x: scroll;
        .single_select_date {
          margin: 5px;
          input[type="radio"] {
            opacity: 1;
            position: fixed;
            width: 0;
          }
          label {
            width: 45px;
            border-radius: 10px;
            border: 1px solid #f3f2f2;
            background-color: #ffffff;
            cursor: pointer;
            padding: 5px;
            .text_content_wrapper_1 {
              .text_content_1 {
                margin: 0;
                font-size: 16px;
                font-weight: 500;
                text-align: center;
                color: #6f6868;
              }
            }
            .text_content_wrapper_2 {
              .text_content_2 {
                margin: 0;
                font-size: 16px;
                font-weight: 500;
                text-align: center;
                color: #6f6868;
              }
            }
          }
          input[type="radio"]:checked + label {
            border: none;
            background-color: #6d48ef;
            .text_content_wrapper_1 {
              .text_content_1 {
                color: #ffffff;
              }
            }
            .text_content_wrapper_2 {
              .text_content_2 {
                color: #ffffff;
              }
            }
          }
        }
      }
      .date_time_collection::-webkit-scrollbar {
        height: 10px;
      }
      .date_time_collection::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
      }
      .date_time_collection::-webkit-scrollbar-thumb {
        background-color: #6d48ef45;
        border-radius: 10px;
      }
      .date_time_collection::-webkit-scrollbar-thumb:hover {
        background-color: #6d48ef;
      }
    }
    .sec_3_wrapper {
      margin: 10px 0;
      .classes_collection {
        display: flex;
        flex-direction: column;
        .single_class_wrapper {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          border-radius: 8px;
          background-color: #faf9fe;
          padding: 10px;
          margin: 10px 0;
          cursor: pointer;
          .left_side_container_wrapper {
            width: auto;
            .image_wrapper {
              text-align: center;
              img {
                width: 100px;
              }
            }
          }
          .right_side_container_wrapper {
            width: auto;
            margin: 0 10px;
            width: 100%;
            .text_content_wrapper_1 {
              .text_content_1 {
                margin: 0;
                font-size: 16px;
                font-weight: 600;
                color: #0f0404;
              }
            }
            .text_content_wrapper_2 {
              .text_content_2 {
                margin: 0;
                font-size: 14px;
                font-weight: 500;
                color: #6f6868;
              }
            }
            .sub_content_wrapper {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              .left_side_container_wrapper {
                flex: 0 0 50%;
                .text_content_wrapper {
                  .text_content {
                    margin: 0;
                    font-size: 12px;
                    font-weight: 500;
                    color: #0f0404;
                    img {
                      margin-right: 5px;
                    }
                  }
                }
              }
              .right_side_container_wrapper {
                flex: 0 0 50%;
                text-align: right;
                .subject_name {
                  padding: 5px;
                  border-radius: 5px;
                  background-image: linear-gradient(
                    120deg,
                    #ff9600 37%,
                    #ff5d00 72%
                  );
                  font-size: 12px;
                  font-weight: 600;
                  color: #ffffff;
                }
              }
            }
          }
        }
        .no_data_found_wrapper {
          margin: 5px 0;
          .text_content_wrapper {
            p {
              margin: 0;
              font-size: 18px;
              font-weight: 600;
            }
          }
          img {
            width: 200px;
          }
        }
      }
    }
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .scheduleClass_wrapper {
    .scheduleClass_inner_wrapper {
      padding: 10px;
      .sec_1_wrapper {
        .text_content_wrapper {
          .text_content {
          }
        }
      }
      .sec_2_wrapper {
        .date_time_collection {
          .single_select_date {
            input[type="radio"] {
            }
            label {
              .text_content_wrapper_1 {
                .text_content_1 {
                }
              }
              .text_content_wrapper_2 {
                .text_content_2 {
                }
              }
            }
            input[type="radio"]:checked + label {
              .text_content_wrapper_1 {
                .text_content_1 {
                }
              }
              .text_content_wrapper_2 {
                .text_content_2 {
                }
              }
            }
          }
        }
        .date_time_collection::-webkit-scrollbar {
        }
        .date_time_collection::-webkit-scrollbar-track {
        }
        .date_time_collection::-webkit-scrollbar-thumb {
        }
        .date_time_collection::-webkit-scrollbar-thumb:hover {
        }
      }
      .sec_3_wrapper {
        .classes_collection {
          .single_class_wrapper {
            .left_side_container_wrapper {
              .image_wrapper {
                img {
                }
              }
            }
            .right_side_container_wrapper {
              .text_content_wrapper_1 {
                .text_content_1 {
                }
              }
              .text_content_wrapper_2 {
                .text_content_2 {
                }
              }
              .sub_content_wrapper {
                .left_side_container_wrapper {
                  flex: 0 0 50%;
                  .text_content_wrapper {
                    .text_content {
                      img {
                      }
                    }
                  }
                }
                .right_side_container_wrapper {
                  .subject_name {
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .scheduleClass_wrapper {
    .scheduleClass_inner_wrapper {
      padding: 5px;
      .sec_1_wrapper {
        .text_content_wrapper {
          .text_content {
          }
        }
      }
      .sec_2_wrapper {
        .date_time_collection {
          .single_select_date {
            input[type="radio"] {
            }
            label {
              .text_content_wrapper_1 {
                .text_content_1 {
                }
              }
              .text_content_wrapper_2 {
                .text_content_2 {
                }
              }
            }
            input[type="radio"]:checked + label {
              .text_content_wrapper_1 {
                .text_content_1 {
                }
              }
              .text_content_wrapper_2 {
                .text_content_2 {
                }
              }
            }
          }
        }
        .date_time_collection::-webkit-scrollbar {
        }
        .date_time_collection::-webkit-scrollbar-track {
        }
        .date_time_collection::-webkit-scrollbar-thumb {
        }
        .date_time_collection::-webkit-scrollbar-thumb:hover {
        }
      }
      .sec_3_wrapper {
        .classes_collection {
          .single_class_wrapper {
            .left_side_container_wrapper {
              .image_wrapper {
                img {
                }
              }
            }
            .right_side_container_wrapper {
              .text_content_wrapper_1 {
                .text_content_1 {
                }
              }
              .text_content_wrapper_2 {
                .text_content_2 {
                }
              }
              .sub_content_wrapper {
                .left_side_container_wrapper {
                  flex: 0 0 50%;
                  .text_content_wrapper {
                    .text_content {
                      img {
                      }
                    }
                  }
                }
                .right_side_container_wrapper {
                  .subject_name {
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .scheduleClass_wrapper {
    .scheduleClass_inner_wrapper {
      .sec_1_wrapper {
        .text_content_wrapper {
          .text_content {
          }
        }
      }
      .sec_2_wrapper {
        .date_time_collection {
          .single_select_date {
            input[type="radio"] {
            }
            label {
              .text_content_wrapper_1 {
                .text_content_1 {
                }
              }
              .text_content_wrapper_2 {
                .text_content_2 {
                }
              }
            }
            input[type="radio"]:checked + label {
              .text_content_wrapper_1 {
                .text_content_1 {
                }
              }
              .text_content_wrapper_2 {
                .text_content_2 {
                }
              }
            }
          }
        }
        .date_time_collection::-webkit-scrollbar {
        }
        .date_time_collection::-webkit-scrollbar-track {
        }
        .date_time_collection::-webkit-scrollbar-thumb {
        }
        .date_time_collection::-webkit-scrollbar-thumb:hover {
        }
      }
      .sec_3_wrapper {
        .classes_collection {
          .single_class_wrapper {
            .left_side_container_wrapper {
              .image_wrapper {
                img {
                }
              }
            }
            .right_side_container_wrapper {
              .text_content_wrapper_1 {
                .text_content_1 {
                }
              }
              .text_content_wrapper_2 {
                .text_content_2 {
                }
              }
              .sub_content_wrapper {
                .left_side_container_wrapper {
                  order: 1;
                  .text_content_wrapper {
                    .text_content {
                      img {
                      }
                    }
                  }
                }
                .right_side_container_wrapper {
                  order: 2;
                  .subject_name {
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
