/* ==========================Custom CSS============================================= */
@font-face {
  font-family: "Montserrat" !important;
  src: local("Montserrat"),
    url("./utilities/fonts/Montserrat-Black.ttf") format("truetype"),
    url("./utilities/fonts/Montserrat-BlackItalic.ttf") format("truetype"),
    url("./utilities/fonts/Montserrat-Bold.ttf") format("truetype"),
    url("./utilities/fonts/Montserrat-BoldItalic.ttf") format("truetype"),
    url("./utilities/fonts/Montserrat-ExtraBold.ttf") format("truetype"),
    url("./utilities/fonts/Montserrat-ExtraBoldItalic.ttf") format("truetype"),
    url("./utilities/fonts/Montserrat-ExtraLight.ttf") format("truetype"),
    url("./utilities/fonts/Montserrat-ExtraLightItalic.ttf") format("truetype"),
    url("./utilities/fonts/Montserrat-Italic.ttf") format("truetype"),
    url("./utilities/fonts/Montserrat-Light.ttf") format("truetype"),
    url("./utilities/fonts/Montserrat-LightItalic.ttf") format("truetype"),
    url("./utilities/fonts/Montserrat-Medium.ttf") format("truetype"),
    url("./utilities/fonts/Montserrat-MediumItalic.ttf") format("truetype"),
    url("./utilities/fonts/Montserrat-Regular.ttf") format("truetype"),
    url("./utilities/fonts/Montserrat-SemiBold.ttf") format("truetype"),
    url("./utilities/fonts/Montserrat-SemiBoldItalic.ttf") format("truetype"),
    url("./utilities/fonts/Montserrat-Thin.ttf") format("truetype"),
    url("./utilities/fonts/Montserrat-ThinItalic.ttf") format("truetype");
}

body {
  overflow: auto ! important;
  padding: 0;
  margin: 0;
  font-family: "Montserrat";
}

.Toastify__toast--success {
  background-color: #6d48ef !important;
}
