.notesFeatureContentComponent_wrapper {
  padding-top: 80px;
  .notesFeatureContentComponent_inner_wrapper {
    padding: 20px;
    .sec_1_wrapper {
      margin: 20px 0;
      .sec_1_inner_wrapper {
        display: flex;
        justify-content: flex-start;
        .btn_wrapper {
          button {
            outline: none;
            border: none;
            background: transparent;
          }
        }
        .text_content_wrapper {
          margin: 0 10px;
          .text_content {
            margin: 0;
            font-size: 20px;
            font-weight: 600;
            color: #0f0404;
          }
        }
      }
    }
    .notes_wrapper {
      .notes_inner_wrapper {
        text-align: center;
        iframe {
          width: 100%;
          height: 600px;
        }
      }
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .notesFeatureContentComponent_wrapper {
    padding-top: 60px;
    .notesFeatureContentComponent_inner_wrapper {
      padding: 20px 5px;
      .notes_wrapper {
        .notes_inner_wrapper {
          iframe {
            width: 100%;
            height: calc(100vh - 60px - 40px);
          }
        }
      }
    }
  }
}
