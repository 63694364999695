.lastPausedVideoView_wrapper {
  margin: 20px 0;
  .lastPausedVideoView_inner_wrapper {
    border-radius: 8px;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.04);
    background-color: #ffffff;
    padding: 20px;
    .section_1_wrapper {
      margin-bottom: 10px;
      .section_1_inner_wrapper {
        .text_content_wrapper {
          .text_content {
            margin: 0;
            font-size: 20px;
            font-weight: 600;
            color: #0f0404;
          }
        }
      }
    }
    .section_2_wrapper {
      .section_2_inner_wrapper {
        .last_paused_video_wrapper {
          display: flex;
          flex-direction: column;
          //   height: 300px;
          //   overflow-y: scroll;
          // .single_video_wrapper {
          //   width: 100%;
          //   border-radius: 8px;
          //   background-color: #faf9fe;
          //   padding: 10px;
          //   margin: 10px 0;
          //   cursor: pointer;
          //   .video_thumbnail {
          //     width: 100%;
          //     position: relative;
          //     .image_wrapper {
          //       text-align: center;
          //       img {
          //         // width: 100%;
          //         height: 150px;
          //       }
          //     }
          //     .video_play_btn {
          //       position: absolute;
          //       top: 50%;
          //       left: 50%;
          //       transform: translate(-50%, -50%);
          //       i {
          //         font-size: 30px;
          //       }
          //     }
          //   }
          //   .video_details {
          //     .text_content_wrapper {
          //       .text_content {
          //         margin: 0;
          //         font-size: 18px;
          //         font-weight: 600;
          //       }
          //     }
          //   }
          // }
          .single_video_wrapper {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            border-radius: 8px;
            background-color: #faf9fe;
            padding: 10px;
            margin: 10px 0;
            cursor: pointer;
            .left_side_content_wrapper {
              width: auto;
              flex: auto;
              .image_wrapper {
                text-align: center;
                img {
                  width: 100px;
                }
              }
            }
            .right_side_content_wrapper {
              flex: auto;
              margin: 0 10px;
              width: 100%;
              .text_content_wrapper_1 {
                .text_content_1 {
                  margin: 0;
                  font-size: 16px;
                  font-weight: 600;
                  color: #0f0404;
                }
              }
              .text_content_wrapper_2 {
                .text_content_2 {
                  margin: 0;
                  font-size: 14px;
                  font-weight: 500;
                  color: #6f6868;
                }
              }
              .sub_content_wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                .left_side_content_wrapper {
                  // flex: 0 0 50%;
                  .label_content_wrapper {
                    label {
                      padding: 5px 10px;
                      border-radius: 15px;
                      background-color: #fef4ec;
                      span {
                        img {
                          width: 15px;
                          height: 15px;
                        }
                      }
                    }
                  }
                }
                .right_side_content_wrapper {
                  // flex: 0 0 50%;
                  text-align: right;
                  margin: 0;
                  padding: 0;
                  width: auto;
                  .subject_name {
                    padding: 5px;
                    border-radius: 5px;
                    background-image: linear-gradient(
                      120deg,
                      #ff9600 37%,
                      #ff5d00 72%
                    );
                    font-size: 12px;
                    font-weight: 600;
                    color: #ffffff;
                  }
                }
              }
            }
          }
        }
        .free_videos_collection::-webkit-scrollbar {
          width: 7px;
        }
        .free_videos_collection::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px grey;
          border-radius: 10px;
        }

        /* Handle */
        .free_videos_collection::-webkit-scrollbar-thumb {
          background: #6d48ef;
          border-radius: 10px;
        }

        /* Handle on hover */
        .free_videos_collection::-webkit-scrollbar-thumb:hover {
          background: #6d48ef;
        }
      }
    }
  }
}
