.modal_body_wrapper {
  .sec_1_wrapper {
    .text_content_wrapper {
      margin: 10px 0;
      .text_content {
        font-size: 20px;
        font-weight: 600;
        text-align: left;
        color: #a02b2d;
      }
    }
    .target_collection_wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      .single_target_wrapper {
        margin: 5px;
        input[type="radio"] {
          opacity: 0;
          position: fixed;
          width: 0;
        }
        label {
          border: solid 1px #f0eef8;
          background-color: #fafafd;
          padding: 10px 20px;
          border-radius: 25px;
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0.2px;
          color: #6b6876;
          cursor: pointer;
          display: flex;
          align-items: center;
          img {
            margin-right: 5px;
          }
        }
        input[type="radio"]:checked + label {
          background-color: #6d48ef;
          border: none;
          color: #ffffff;
        }
      }
    }
  }
  .sec_2_wrapper {
    .text_content_wrapper {
      margin: 10px 0;
      .text_content {
        font-size: 20px;
        font-weight: 600;
        text-align: left;
        color: #a02b2d;
      }
    }
    .class_collection_wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      .single_class_wrapper {
        margin: 5px;
        input[type="radio"] {
          opacity: 0;
          position: fixed;
          width: 0;
        }
        label {
          border: solid 1px #f0eef8;
          background-color: #fafafd;
          padding: 10px 20px;
          border-radius: 25px;
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0.2px;
          color: #6b6876;
          cursor: pointer;
          display: flex;
          align-items: center;
          img {
            margin-right: 5px;
          }
        }
        input[type="radio"]:checked + label {
          background-color: #6d48ef;
          border: none;
          color: #ffffff;
        }
      }
    }
  }
  .sec_3_wrapper {
    .text_content_wrapper {
      margin: 10px 0;
      .text_content {
        font-size: 20px;
        font-weight: 600;
        text-align: left;
        color: #a02b2d;
      }
    }
    .course_collection_wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      .single_course_wrapper {
        margin: 5px;
        input[type="radio"] {
          opacity: 0;
          position: fixed;
          width: 0;
        }
        label {
          border: solid 1px #f0eef8;
          background-color: #fafafd;
          padding: 10px 20px;
          border-radius: 25px;
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0.2px;
          color: #6b6876;
          cursor: pointer;
          display: flex;
          align-items: center;
          img {
            margin-right: 5px;
          }
        }
        input[type="radio"]:checked + label {
          background-color: #6d48ef;
          border: none;
          color: #ffffff;
        }
      }
    }
  }
  .sec_4_wrapper {
    .text_content_wrapper {
      margin: 10px 0;
      .text_content {
        font-size: 20px;
        font-weight: 600;
        text-align: left;
        color: #a02b2d;
      }
    }
    .batch_collection_wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      .single_batch_wrapper {
        margin: 5px;
        input[type="radio"] {
          opacity: 0;
          position: fixed;
          width: 0;
        }
        label {
          border: solid 1px #f0eef8;
          background-color: #fafafd;
          padding: 10px 20px;
          border-radius: 25px;
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0.2px;
          color: #6b6876;
          cursor: pointer;
          display: flex;
          align-items: center;
          img {
            margin-right: 5px;
          }
        }
        input[type="radio"]:checked + label {
          background-color: #6d48ef;
          border: none;
          color: #ffffff;
        }
      }
    }
  }
  .sec_5_wrapper {
    margin: 10px 0;
    .text_content_wrapper {
      .text_content {
        margin: 0;
        font-size: 18px;
        font-weight: 600;
        color: #ff0000;
      }
    }
  }
}

.modal_footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    border: none;
    outline: none;
    width: 150px;
    height: 50px;
    border-radius: 25px;
    background-color: #6d48ef;
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
  }
}
