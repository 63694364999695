.contactUsContentComponent_wrapper {
  padding-top: 80px;
  .contactUsContentComponent_inner_wrapper {
    padding: 20px;
    .sec_1_wrapper {
      margin: 10px 0;
      .sec_1_inner_wrapper {
        .heading_wrapper {
          .heading_text_content {
            margin: 0;
            font-size: 28px;
            font-weight: 600;
            letter-spacing: 0.33px;
            color: #463c34;
          }
        }
      }
    }
    .sec_2_wrapper {
      margin: 10px 0;
      background-color: #ffffff;
      border-radius: 10px;
      .sec_2_inner_wrapper {
        padding: 20px 10px;
        form {
          .input_wrapper {
            margin: 10px 0;
            input[type="text"] {
              width: 100%;
              height: 50px;
              border-radius: 10px;
              border: solid 1px #f2f2f3;
              background-color: #faf9fe;
              padding: 0 10px;
            }
          }
          .description_wrapper {
            margin: 10px 0;
            textarea {
              width: 100%;
              border-radius: 10px;
              border: solid 1px #f2f2f3;
              background-color: #faf9fe;
              padding: 0 10px;
            }
          }
          .btn_wrapper {
            margin: 20px 0;
            text-align: center;
            button {
              border: none;
              outline: none;
              width: 200px;
              height: 50px;
              border-radius: 25px;
              background-color: #6d48ef;
              font-size: 18px;
              font-weight: 600;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}
