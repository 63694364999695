.userAnalyticsContentComponent_wrapper {
  padding-top: 80px;
  .userAnalyticsContentComponent_inner_wrapper {
    padding: 20px;
    .section_wrapper {
      margin: 20px 0;
      .section_inner_wrapper {
        .options_wrapper {
          .custom_nav_pills {
            padding: 10px;
            background: none;
            justify-content: space-between;
            .custom_nav_item {
              flex: 0 0 30%;
              .custom_nav_link {
                width: 100%;
                border-radius: 10px;
                height: 50px;
                border: none;
                outline: none;
                font-size: 16px;
                font-weight: 500;
                color: #6b6876;
                background-color: #f5f2fe;
              }
              .custom_nav_link.active {
                background-color: #ffffff;
                font-weight: 600;
              }
            }
          }
        }
        .tab_wrapper {
          .custom_tab_content {
            //////overall test tab////
            .custom_tab_pane {
              .overall_test_tab_wrapper {
                .outer_content_wrapper {
                  display: flex;
                  justify-content: space-between;
                  .left_side_content_wrapper {
                    flex: 0 0 48%;
                    .sec_1_wrapper {
                      background-color: #ffffff;
                      border-radius: 10px;
                      margin: 10px 0;
                      .sec_1_inner_wrapper {
                        padding: 10px;
                      }
                    }
                    .sec_2_wrapper {
                      background-color: #ffffff;
                      border-radius: 10px;
                      margin: 10px 0;
                      .sec_2_inner_wrapper {
                        padding: 10px;
                        .progress_bar_collection {
                          .single_progress_bar_wrapper {
                            margin: 5px 0;
                            .progress_bar_heading_wrapper {
                              .progress_bar_heading_content {
                                margin: 0;
                                font-size: 14px;
                                font-weight: 500;
                                color: #6b6876;
                              }
                            }
                            .progress_bar_content_wrapper {
                              display: flex;
                              justify-content: space-between;
                              align-items: center;
                              .progress_bar_wrapper {
                                flex: 0 0 80%;
                                progress {
                                  -webkit-appearance: none;
                                  -moz-appearance: none;
                                  appearance: none;
                                  width: 100%;
                                  height: 10px;
                                  border-radius: 10px;
                                }
                                progress::-webkit-progress-bar {
                                  background: #f2f2f3;
                                  border-radius: 25px;
                                }
                                progress::-webkit-progress-value {
                                  background: #a02b2d;
                                  border-radius: 25px;
                                }
                                progress::-moz-progress-bar {
                                  background: #8d8d99;
                                  border-radius: 25px;
                                }
                                progress::-moz-progress-value {
                                  background: #a02b2d;
                                  border-radius: 25px;
                                }
                              }
                              .percentage_content_wrapper {
                                flex: 0 0 10%;
                                .percentage_content {
                                  margin: 0;
                                  font-size: 14px;
                                  font-weight: 500;
                                  color: #09041a;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  .right_side_content_wrapper {
                    flex: 0 0 48%;
                    .sec_1_wrapper {
                      background-color: #ffffff;
                      border-radius: 10px;
                      margin: 10px 0;
                      .sec_1_inner_wrapper {
                        padding: 10px;
                        .heading_wrapper {
                          .heading_content_wrapper {
                            margin: 0;
                            font-size: 20px;
                            font-weight: 600;
                            color: #0f0404;
                          }
                        }
                        .all_tests_collection_wrapper {
                          display: flex;
                          flex-direction: column;
                          .single_test_wrapper {
                            flex: 0 0 100%;
                            padding: 20px;
                            border-radius: 16px;
                            border: solid 1px #f0eef8;
                            background-color: #fafafd;
                            margin: 10px 0;
                            cursor: pointer;
                            .sub_section_wrapper_1 {
                              margin: 5px 0;
                              label {
                                border-radius: 15px;
                                background-color: #3aa02b;
                                padding: 5px 10px;
                                font-size: 12px;
                                font-weight: 500;
                                color: #ffffff;
                              }
                            }
                            .sub_section_wrapper_2 {
                              margin: 5px 0;
                              .text_content_wrapper {
                                .text_content {
                                  margin: 0;
                                  font-size: 16px;
                                  font-weight: 500;
                                  color: #0f0404;
                                }
                              }
                            }
                            .sub_section_wrapper_3 {
                              margin: 5px 0;
                              .sub_content_wrapper {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                .left_content_wrapper {
                                  label {
                                    font-size: 14px;
                                    font-weight: 500;
                                    text-align: center;
                                    color: #6b6876;
                                  }
                                }
                                .right_content_wrapper {
                                  .lbl_1 {
                                    font-size: 14px;
                                    font-weight: 500;
                                    text-align: center;
                                    color: #a02b2d;
                                  }
                                  .lbl_2 {
                                    font-size: 14px;
                                    font-weight: 500;
                                    text-align: center;
                                    color: #6d48ef;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            //////individual test tab////
            .custom_tab_pane {
              .individual_test_tab_wrapper {
                background-color: #ffffff;
                border-radius: 10px;
                .outer_content_wrapper {
                  padding: 0 10px;
                  display: flex;
                  justify-content: space-between;
                  padding: 20px;
                  .left_side_content_wrapper {
                    flex: 0 0 25%;
                    .subject_collection_wrapper {
                      display: flex;
                      flex-wrap: wrap;
                      flex-direction: column;
                      align-items: center;
                      .single_select_subject {
                        margin: 10px 0;
                        input[type="radio"] {
                          opacity: 0;
                          position: fixed;
                          width: 0;
                        }
                        label {
                          border: solid 1px #f0eef8;
                          background-color: #fafafd;
                          padding: 10px 20px;
                          border-radius: 25px;
                          font-size: 16px;
                          font-weight: 500;
                          letter-spacing: 0.2px;
                          color: #6b6876;
                          cursor: pointer;
                          display: flex;
                          align-items: center;
                        }
                        input[type="radio"]:checked + label {
                          background-color: #6d48ef;
                          border: none;
                          color: #ffffff;
                        }
                      }
                    }
                  }
                  .right_side_content_wrapper {
                    flex: 0 0 65%;
                    .tests_collection_wrapper {
                      display: flex;
                      flex-direction: column;
                      height: 450px;
                      overflow-y: scroll;
                      padding: 10px;
                      .single_test_wrapper {
                        // flex: 0 0 100%;
                        padding: 20px;
                        border-radius: 16px;
                        border: solid 1px #f0eef8;
                        background-color: #fafafd;
                        margin: 10px 0;
                        cursor: pointer;
                        .sub_section_wrapper_1 {
                          margin: 5px 0;
                          label {
                            border-radius: 15px;
                            background-color: #3aa02b;
                            padding: 5px 10px;
                            font-size: 12px;
                            font-weight: 500;
                            color: #ffffff;
                          }
                        }
                        .sub_section_wrapper_2 {
                          margin: 5px 0;
                          .text_content_wrapper {
                            .text_content {
                              margin: 0;
                              font-size: 16px;
                              font-weight: 500;
                              color: #0f0404;
                            }
                          }
                        }
                        .sub_section_wrapper_3 {
                          margin: 5px 0;
                          .sub_content_wrapper {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            .left_content_wrapper {
                              label {
                                font-size: 14px;
                                font-weight: 500;
                                text-align: center;
                                color: #6b6876;
                              }
                            }
                            .right_content_wrapper {
                              .lbl_1 {
                                font-size: 14px;
                                font-weight: 500;
                                text-align: center;
                                color: #6d48ef;
                                text-decoration: underline;
                              }
                            }
                          }
                        }
                      }
                    }
                    .tests_collection_wrapper::-webkit-scrollbar {
                      width: 7px;
                    }
                    .tests_collection_wrapper::-webkit-scrollbar-track {
                      box-shadow: inset 0 0 5px grey;
                      border-radius: 10px;
                    }

                    /* Handle */
                    .tests_collection_wrapper::-webkit-scrollbar-thumb {
                      background: #6d48ef;
                      border-radius: 10px;
                    }

                    /* Handle on hover */
                    .tests_collection_wrapper::-webkit-scrollbar-thumb:hover {
                      background: #6d48ef;
                    }
                    .navigation_btn_wrapper {
                      margin: 10px 0;
                      .navigation_btn_inner_wrapper {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .btn_wrapper {
                          button {
                            border: none;
                            outline: none;
                            width: auto;
                            height: 45px;
                            border-radius: 5px;
                            border: 2px solid #6d48ef;
                            background-color: #6d48ef;
                            font-size: 16px;
                            font-weight: 600;
                            text-align: center;
                            color: #ffffff;
                            padding: 0 10px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            //////learn analysis tab////
            .custom_tab_pane {
              .learn_analysis_tab_wrapper {
                background-color: #ffffff;
                border-radius: 10px;
                .outer_content_wrapper {
                  padding: 0 10px;
                  display: flex;
                  justify-content: space-between;
                  padding: 20px;
                  .left_side_content_wrapper {
                    flex: 0 0 25%;
                    .subject_collection_wrapper {
                      display: flex;
                      flex-wrap: wrap;
                      flex-direction: column;
                      align-items: center;
                      .single_select_subject {
                        margin: 10px 0;
                        input[type="radio"] {
                          opacity: 0;
                          position: fixed;
                          width: 0;
                        }
                        label {
                          border: solid 1px #f0eef8;
                          background-color: #fafafd;
                          padding: 10px 20px;
                          border-radius: 25px;
                          font-size: 16px;
                          font-weight: 500;
                          letter-spacing: 0.2px;
                          color: #6b6876;
                          cursor: pointer;
                          display: flex;
                          align-items: center;
                        }
                        input[type="radio"]:checked + label {
                          background-color: #6d48ef;
                          border: none;
                          color: #ffffff;
                        }
                      }
                    }
                  }
                  .right_side_content_wrapper {
                    flex: 0 0 65%;
                    .sub_content_1 {
                      display: flex;
                      justify-content: space-evenly;
                      align-items: center;
                      flex-wrap: wrap;
                      .graph_wrapper {
                        flex: 0 0 45%;
                        margin: 5px;
                      }
                    }
                    .sub_content_2 {
                      .progress_bar_collection {
                        .single_progress_bar_wrapper {
                          margin: 5px 0;
                          .progress_bar_heading_wrapper {
                            .progress_bar_heading_content {
                              margin: 0;
                              font-size: 14px;
                              font-weight: 500;
                              color: #6b6876;
                            }
                          }
                          .progress_bar_content_wrapper {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            .progress_bar_wrapper {
                              flex: 0 0 80%;
                              progress {
                                -webkit-appearance: none;
                                -moz-appearance: none;
                                appearance: none;
                                width: 100%;
                                height: 10px;
                                border-radius: 10px;
                              }
                              progress::-webkit-progress-bar {
                                background: #f2f2f3;
                                border-radius: 25px;
                              }
                              progress::-webkit-progress-value {
                                background: #a02b2d;
                                border-radius: 25px;
                              }
                              progress::-moz-progress-bar {
                                background: #8d8d99;
                                border-radius: 25px;
                              }
                              progress::-moz-progress-value {
                                background: #a02b2d;
                                border-radius: 25px;
                              }
                            }
                            .percentage_content_wrapper {
                              flex: 0 0 10%;
                              .percentage_content {
                                margin: 0;
                                font-size: 14px;
                                font-weight: 500;
                                color: #09041a;
                              }
                            }
                          }
                        }
                      }
                    }

                    .tests_collection_wrapper {
                      display: flex;
                      flex-direction: column;
                      .single_test_wrapper {
                        flex: 0 0 100%;
                        padding: 20px;
                        border-radius: 16px;
                        border: solid 1px #f0eef8;
                        background-color: #fafafd;
                        margin: 10px 0;
                        cursor: pointer;
                        .sub_section_wrapper_1 {
                          margin: 5px 0;
                          label {
                            border-radius: 15px;
                            background-color: #3aa02b;
                            padding: 5px 10px;
                            font-size: 12px;
                            font-weight: 500;
                            color: #ffffff;
                          }
                        }
                        .sub_section_wrapper_2 {
                          margin: 5px 0;
                          .text_content_wrapper {
                            .text_content {
                              margin: 0;
                              font-size: 16px;
                              font-weight: 500;
                              color: #0f0404;
                            }
                          }
                        }
                        .sub_section_wrapper_3 {
                          margin: 5px 0;
                          .sub_content_wrapper {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            .left_content_wrapper {
                              label {
                                font-size: 14px;
                                font-weight: 500;
                                text-align: center;
                                color: #6b6876;
                              }
                            }
                            .right_content_wrapper {
                              .lbl_1 {
                                font-size: 14px;
                                font-weight: 500;
                                text-align: center;
                                color: #6d48ef;
                                text-decoration: underline;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .userAnalyticsContentComponent_wrapper {
    padding-top: 60px;
    .userAnalyticsContentComponent_inner_wrapper {
      padding: 10px;
      .section_wrapper {
        margin: 10px 0;
        .section_inner_wrapper {
          .options_wrapper {
            .custom_nav_pills {
              padding: 5px;
              .custom_nav_item {
                .custom_nav_link {
                  height: 60px;
                }
                .custom_nav_link.active {
                }
              }
            }
          }
          .tab_wrapper {
            .custom_tab_content {
              //////overall test tab////
              .custom_tab_pane {
                .overall_test_tab_wrapper {
                  .outer_content_wrapper {
                    padding: 10px;
                    flex-direction: column;
                    .left_side_content_wrapper {
                      flex: 0 0 100%;
                      .sec_1_wrapper {
                        .sec_1_inner_wrapper {
                        }
                      }
                      .sec_2_wrapper {
                        .sec_2_inner_wrapper {
                          .progress_bar_collection {
                            .single_progress_bar_wrapper {
                              .progress_bar_heading_wrapper {
                                .progress_bar_heading_content {
                                }
                              }
                              .progress_bar_content_wrapper {
                                .progress_bar_wrapper {
                                  progress {
                                  }
                                  progress::-webkit-progress-bar {
                                  }
                                  progress::-webkit-progress-value {
                                  }
                                  progress::-moz-progress-bar {
                                  }
                                  progress::-moz-progress-value {
                                  }
                                }
                                .percentage_content_wrapper {
                                  .percentage_content {
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    .right_side_content_wrapper {
                      flex: 0 0 100%;
                      .sec_1_wrapper {
                        .sec_1_inner_wrapper {
                          .heading_wrapper {
                            .heading_content_wrapper {
                            }
                          }
                          .all_tests_collection_wrapper {
                            .single_test_wrapper {
                              .sub_section_wrapper_1 {
                                label {
                                }
                              }
                              .sub_section_wrapper_2 {
                                .text_content_wrapper {
                                  .text_content {
                                  }
                                }
                              }
                              .sub_section_wrapper_3 {
                                .sub_content_wrapper {
                                  .left_content_wrapper {
                                    label {
                                    }
                                  }
                                  .right_content_wrapper {
                                    .lbl_1 {
                                    }
                                    .lbl_2 {
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              //////individual test tab////
              .custom_tab_pane {
                .individual_test_tab_wrapper {
                  .outer_content_wrapper {
                    flex-direction: column;
                    .left_side_content_wrapper {
                      flex: 0 0 100%;
                      .subject_collection_wrapper {
                        flex-direction: row;
                        .single_select_subject {
                          margin: 5px;
                          input[type="radio"] {
                          }
                          label {
                          }
                          input[type="radio"]:checked + label {
                          }
                        }
                      }
                    }
                    .right_side_content_wrapper {
                      flex: 0 0 100%;
                      .tests_collection_wrapper {
                        height: auto;
                        .single_test_wrapper {
                          .sub_section_wrapper_1 {
                            label {
                            }
                          }
                          .sub_section_wrapper_2 {
                            .text_content_wrapper {
                              .text_content {
                              }
                            }
                          }
                          .sub_section_wrapper_3 {
                            .sub_content_wrapper {
                              .left_content_wrapper {
                                label {
                                }
                              }
                              .right_content_wrapper {
                                .lbl_1 {
                                }
                              }
                            }
                          }
                        }
                      }
                      .tests_collection_wrapper::-webkit-scrollbar {
                      }
                      .tests_collection_wrapper::-webkit-scrollbar-track {
                      }

                      /* Handle */
                      .tests_collection_wrapper::-webkit-scrollbar-thumb {
                      }

                      /* Handle on hover */
                      .tests_collection_wrapper::-webkit-scrollbar-thumb:hover {
                      }
                      .navigation_btn_wrapper {
                        .navigation_btn_inner_wrapper {
                          .btn_wrapper {
                            button {
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              //////learn analysis tab////
              .custom_tab_pane {
                .learn_analysis_tab_wrapper {
                  .outer_content_wrapper {
                    flex-direction: column;
                    .left_side_content_wrapper {
                      flex: 0 0 100%;
                      .subject_collection_wrapper {
                        flex-direction: row;
                        .single_select_subject {
                          margin: 5px;
                          input[type="radio"] {
                          }
                          label {
                          }
                          input[type="radio"]:checked + label {
                          }
                        }
                      }
                    }
                    .right_side_content_wrapper {
                      flex: 0 0 100%;
                      .sub_content_1 {
                        .graph_wrapper {
                        }
                      }
                      .sub_content_2 {
                        .progress_bar_collection {
                          .single_progress_bar_wrapper {
                            .progress_bar_heading_wrapper {
                              .progress_bar_heading_content {
                              }
                            }
                            .progress_bar_content_wrapper {
                              .progress_bar_wrapper {
                                progress {
                                }
                                progress::-webkit-progress-bar {
                                }
                                progress::-webkit-progress-value {
                                }
                                progress::-moz-progress-bar {
                                }
                                progress::-moz-progress-value {
                                }
                              }
                              .percentage_content_wrapper {
                                .percentage_content {
                                }
                              }
                            }
                          }
                        }
                      }

                      .tests_collection_wrapper {
                        .single_test_wrapper {
                          .sub_section_wrapper_1 {
                            label {
                            }
                          }
                          .sub_section_wrapper_2 {
                            .text_content_wrapper {
                              .text_content {
                              }
                            }
                          }
                          .sub_section_wrapper_3 {
                            .sub_content_wrapper {
                              .left_content_wrapper {
                                label {
                                }
                              }
                              .right_content_wrapper {
                                .lbl_1 {
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
