.faqsContentComponent_wrapper {
  padding-top: 80px;
  .faqsContentComponent_inner_wrapper {
    padding: 20px;
    .heading_wrapper {
      .heading_text_content {
        margin: 0;
        font-size: 28px;
        font-weight: 600;
        letter-spacing: 0.33px;
        color: #463c34;
      }
    }
    .tab_accordion_section {
      margin: 30px 0;
      .tab_accordion_wrapper {
        .customAccordion {
          .custom_accordion_item {
            border: 0;
            border-bottom: 1px solid #7070701f;
            .custom_accordion_header {
            }
            .custom_accordion_collapse {
              .custom_accordion_body {
              }
            }
          }
        }
      }
    }
  }
}
