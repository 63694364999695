.userExerciseTestContentComponent_wrapper {
  padding-top: 80px;
  .userExerciseTestContentComponent_inner_wrapper {
    padding: 20px;
    .section_wrapper {
      margin: 20px 0;
      .section_inner_wrapper {
        .options_wrapper {
          .custom_nav_pills {
            padding: 10px;
            background: none;
            justify-content: space-between;
            .custom_nav_item {
              flex: 0 0 48%;
              .custom_nav_link {
                width: 100%;
                border-radius: 10px;
                height: 50px;
                border: none;
                outline: none;
                font-size: 16px;
                font-weight: 500;
                color: #6b6876;
                background-color: #f5f2fe;
              }
              .custom_nav_link.active {
                background-color: #ffffff;
                font-weight: 600;
              }
            }
          }
        }
        .tab_wrapper {
          .custom_tab_content {
            //////question bank tab////
            .custom_tab_pane {
              .question_bank_tab_wrapper {
                .outer_content_wrapper {
                  .sub_content_wrapper_1 {
                    .content_wrapper {
                      display: flex;
                      justify-content: flex-start;
                      .content_inner_wrapper {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border-radius: 8px;
                        box-shadow: 0 4px 16px 0 rgba(109, 72, 239, 0.08);
                        background-color: #ffffff;
                        padding: 10px;
                        cursor: pointer;
                        .image_wrapper {
                          img {
                            width: 30px;
                          }
                        }
                        .text_content_wrapper {
                          margin-left: 5px;
                          .text_content {
                            margin: 0;
                            font-size: 14px;
                            font-weight: 500;
                            color: #0f0404;
                          }
                        }
                      }
                    }
                  }
                  .sub_content_wrapper_2 {
                    margin: 10px 0;
                    background-color: #ffffff;
                    border-radius: 10px;
                    .question_bank_collection_wrapper {
                      width: 100%;
                      display: flex;
                      flex-direction: column;
                      height: 450px;
                      overflow-y: scroll;
                      padding: 10px;
                      .single_question_bank_wrapper {
                        width: 100%;
                        border-radius: 15px;
                        border: solid 1px #f0eef8;
                        background-color: #fafafd;
                        padding: 10px;
                        margin: 10px 0;
                        cursor: pointer;
                        .single_question_bank_content_wrapper_1 {
                          display: flex;
                          justify-content: flex-end;
                          align-items: center;
                          .delete_btn_wrapper {
                            margin: 0 10px;
                            button {
                              border: none;
                              outline: none;
                              background: none;
                              i {
                                color: #d00d0d;
                                font-size: 18px;
                              }
                            }
                          }
                        }
                        .single_question_bank_content_wrapper_2 {
                          margin-top: 5px;
                          display: flex;
                          justify-content: flex-start;
                          align-items: center;
                          .serial_number_wrapper {
                            border-radius: 15px;
                            background-image: linear-gradient(
                              149deg,
                              #fff5e6 32%,
                              #ffefe6 76%
                            );
                            padding: 15px;
                            .text_content_wrapper {
                              .text_content {
                                margin: 0;
                                font-size: 20px;
                                font-weight: 600;
                                text-align: center;
                                color: #b35557;
                              }
                            }
                          }
                          .exercise_description_wrapper {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 100%;
                            padding: 0 10px;
                            .exercise_details_wrapper {
                              .text_content_wrapper_1 {
                                .text_content_1 {
                                  margin: 0;
                                  font-size: 16px;
                                  font-weight: 500;
                                  color: #0f0404;
                                }
                              }
                              .text_content_wrapper_2 {
                                .text_content_2 {
                                  margin: 0;
                                  font-size: 14px;
                                  font-weight: 500;
                                  color: #6b6876;
                                }
                              }
                            }
                            .action_wrapper {
                              display: flex;
                              align-items: center;
                              .forward_btn_wrapper {
                                margin: 0 10px;
                                button {
                                  border: none;
                                  outline: none;
                                  background: none;
                                  label {
                                    padding: 5px;
                                    border-radius: 25px;
                                    background-color: #0dd0177d;
                                    font-size: 14px;
                                    font-weight: 600;
                                    cursor: pointer;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    .question_bank_collection_wrapper::-webkit-scrollbar {
                      width: 7px;
                    }
                    .question_bank_collection_wrapper::-webkit-scrollbar-track {
                      box-shadow: inset 0 0 5px grey;
                      border-radius: 10px;
                    }

                    /* Handle */
                    .question_bank_collection_wrapper::-webkit-scrollbar-thumb {
                      background: #6d48ef;
                      border-radius: 10px;
                    }

                    /* Handle on hover */
                    .question_bank_collection_wrapper::-webkit-scrollbar-thumb:hover {
                      background: #6d48ef;
                    }
                  }
                }
              }
            }
            //////test series tab////
            .custom_tab_pane {
              .previous_class_tab_wrapper {
                .outer_content_wrapper {
                  .liveClasses_collection_wrapper {
                    display: flex;
                    flex-direction: column;
                    .single_liveClass_wrapper {
                      width: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: flex-start;
                      border-radius: 8px;
                      background-color: #faf9fe;
                      padding: 10px;
                      margin: 10px 0;
                      .left_side_content_wrapper {
                        width: auto;
                        flex: auto;
                        .image_wrapper {
                          text-align: center;
                          img {
                          }
                        }
                      }
                      .right_side_content_wrapper {
                        flex: auto;
                        margin: 0 10px;
                        width: 100%;
                        .text_content_wrapper_1 {
                          .text_content_1 {
                            margin: 0;
                            font-size: 16px;
                            font-weight: 600;
                            color: #0f0404;
                          }
                        }
                        .text_content_wrapper_2 {
                          .text_content_2 {
                            margin: 0;
                            font-size: 14px;
                            font-weight: 500;
                            color: #6f6868;
                          }
                        }
                        .sub_content_wrapper {
                          display: flex;
                          align-items: center;
                          justify-content: space-between;
                          width: 100%;
                          .left_side_content_wrapper {
                            // flex: 0 0 50%;
                            .label_content_wrapper {
                              label {
                                padding: 5px 10px;
                                border-radius: 15px;
                                // background-color: #fef4ec;
                                span {
                                  img {
                                    width: 15px;
                                    height: 15px;
                                  }
                                }
                              }
                            }
                          }
                          .right_side_content_wrapper {
                            // flex: 0 0 50%;
                            text-align: right;
                            margin: 0;
                            padding: 0;
                            width: auto;
                            .text_content_wrapper {
                              .text_content {
                                margin: 0;
                                font-size: 14px;
                                font-weight: 600;
                                text-decoration: underline;
                                color: #6d48ef;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  .sec_3_wrapper {
                    margin: 10px 0;
                    .sec_3_inner_wrapper {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      .btn_wrapper {
                        button {
                          border: none;
                          outline: none;
                          width: auto;
                          height: 45px;
                          border-radius: 5px;
                          border: 2px solid #6d48ef;
                          background-color: #6d48ef;
                          font-size: 16px;
                          font-weight: 600;
                          text-align: center;
                          color: #ffffff;
                          padding: 0 10px;
                        }
                      }
                    }
                  }
                }
              }
            }
            //////tests tab////
            .custom_tab_pane {
              .tests_tab_wrapper {
                background-color: #ffffff;
                border-radius: 10px;
                .outer_content_wrapper {
                  .content_wrapper {
                    display: flex;
                    justify-content: space-evenly;
                    padding: 20px 0;
                    .left_side_content_wrapper {
                      flex: 0 0 25%;
                      .tests_type_collection_wrapper {
                        display: flex;
                        flex-wrap: wrap;
                        flex-direction: column;
                        align-items: center;
                        .single_select_test_type {
                          margin: 10px 0;
                          width: 100%;
                          input[type="radio"] {
                            opacity: 0;
                            position: fixed;
                            width: 0;
                          }
                          label {
                            border: solid 1px #f0eef8;
                            background-color: #fafafd;
                            padding: 10px 20px;
                            border-radius: 25px;
                            font-size: 16px;
                            font-weight: 500;
                            letter-spacing: 0.2px;
                            color: #6b6876;
                            cursor: pointer;
                            width: 100%;
                            text-align: center;
                          }
                          input[type="radio"]:checked + label {
                            background-color: #6d48ef;
                            border: none;
                            color: #ffffff;
                          }
                        }
                      }
                    }
                    .right_side_content_wrapper {
                      flex: 0 0 65%;
                      padding: 0 10px;
                      ////////////////////all tests/////////////////
                      .all_tests_wrapper {
                        .all_tests_inner_wrapper {
                          .all_tests_collection_wrapper {
                            display: flex;
                            flex-direction: column;
                            .single_test_wrapper {
                              flex: 0 0 100%;
                              padding: 20px;
                              border-radius: 16px;
                              border: solid 1px #f0eef8;
                              background-color: #fafafd;
                              margin: 10px 0;
                              cursor: pointer;
                              .sub_section_wrapper_1 {
                                margin: 5px 0;
                                label {
                                  border-radius: 15px;
                                  background-color: #3aa02b;
                                  padding: 5px 10px;
                                  font-size: 12px;
                                  font-weight: 500;
                                  color: #ffffff;
                                }
                              }
                              .sub_section_wrapper_2 {
                                margin: 5px 0;
                                .text_content_wrapper {
                                  .text_content {
                                    margin: 0;
                                    font-size: 16px;
                                    font-weight: 500;
                                    color: #0f0404;
                                  }
                                }
                              }
                              .sub_section_wrapper_3 {
                                margin: 5px 0;
                                .sub_content_wrapper {
                                  display: flex;
                                  align-items: center;
                                  justify-content: space-between;
                                  .left_content_wrapper {
                                    label {
                                      font-size: 14px;
                                      font-weight: 500;
                                      text-align: center;
                                      color: #6b6876;
                                    }
                                  }
                                  .right_content_wrapper {
                                    .lbl_1 {
                                      font-size: 14px;
                                      font-weight: 500;
                                      text-align: center;
                                      color: #a02b2d;
                                      cursor: pointer;
                                    }
                                    .lbl_2 {
                                      font-size: 14px;
                                      font-weight: 500;
                                      text-align: center;
                                      color: #6d48ef;
                                      cursor: pointer;
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .userExerciseTestContentComponent_wrapper {
    padding-top: 60px;
    .userExerciseTestContentComponent_inner_wrapper {
      padding: 10px;
      .section_wrapper {
        margin: 10px 0;
        .section_inner_wrapper {
          .options_wrapper {
            .custom_nav_pills {
              .custom_nav_item {
                .custom_nav_link {
                  height: 60px;
                }
                .custom_nav_link.active {
                }
              }
            }
          }
          .tab_wrapper {
            .custom_tab_content {
              //////question bank tab////
              .custom_tab_pane {
                .question_bank_tab_wrapper {
                  .outer_content_wrapper {
                    .sub_content_wrapper_1 {
                      .content_wrapper {
                        .content_inner_wrapper {
                          .image_wrapper {
                            img {
                            }
                          }
                          .text_content_wrapper {
                            .text_content {
                            }
                          }
                        }
                      }
                    }
                    .sub_content_wrapper_2 {
                      .question_bank_collection_wrapper {
                        padding: 5px;
                        .single_question_bank_wrapper {
                          .serial_number_wrapper {
                            .text_content_wrapper {
                              .text_content {
                              }
                            }
                          }
                          .exercise_description_wrapper {
                            .exercise_details_wrapper {
                              .text_content_wrapper_1 {
                                .text_content_1 {
                                }
                              }
                              .text_content_wrapper_2 {
                                .text_content_2 {
                                }
                              }
                            }
                            .action_wrapper {
                              .delete_btn_wrapper {
                                button {
                                  i {
                                  }
                                }
                              }
                              .forward_btn_wrapper {
                                button {
                                  i {
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      .question_bank_collection_wrapper::-webkit-scrollbar {
                      }
                      .question_bank_collection_wrapper::-webkit-scrollbar-track {
                      }

                      /* Handle */
                      .question_bank_collection_wrapper::-webkit-scrollbar-thumb {
                      }

                      /* Handle on hover */
                      .question_bank_collection_wrapper::-webkit-scrollbar-thumb:hover {
                      }
                    }
                  }
                }
              }
              //////test series tab////
              .custom_tab_pane {
                .previous_class_tab_wrapper {
                  .outer_content_wrapper {
                    .liveClasses_collection_wrapper {
                      .single_liveClass_wrapper {
                        .left_side_content_wrapper {
                          .image_wrapper {
                            img {
                            }
                          }
                        }
                        .right_side_content_wrapper {
                          .text_content_wrapper_1 {
                            .text_content_1 {
                            }
                          }
                          .text_content_wrapper_2 {
                            .text_content_2 {
                            }
                          }
                          .sub_content_wrapper {
                            .left_side_content_wrapper {
                              .label_content_wrapper {
                                label {
                                  span {
                                    img {
                                    }
                                  }
                                }
                              }
                            }
                            .right_side_content_wrapper {
                              .text_content_wrapper {
                                .text_content {
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    .sec_3_wrapper {
                      .sec_3_inner_wrapper {
                        .btn_wrapper {
                          button {
                          }
                        }
                      }
                    }
                  }
                }
              }
              //////tests tab////
              .custom_tab_pane {
                .tests_tab_wrapper {
                  .outer_content_wrapper {
                    .content_wrapper {
                      flex-direction: column;
                      padding: 10px 0;
                      .left_side_content_wrapper {
                        flex: 0 0 100%;
                        .tests_type_collection_wrapper {
                          flex-direction: row;
                          .single_select_test_type {
                            width: auto;
                            margin: 5px;
                            input[type="radio"] {
                            }
                            label {
                            }
                            input[type="radio"]:checked + label {
                            }
                          }
                        }
                      }
                      .right_side_content_wrapper {
                        flex: 0 0 100%;
                        ////////////////////all tests/////////////////
                        .all_tests_wrapper {
                          .all_tests_inner_wrapper {
                            .all_tests_collection_wrapper {
                              .single_test_wrapper {
                                .sub_section_wrapper_1 {
                                  label {
                                  }
                                }
                                .sub_section_wrapper_2 {
                                  .text_content_wrapper {
                                    .text_content {
                                    }
                                  }
                                }
                                .sub_section_wrapper_3 {
                                  .sub_content_wrapper {
                                    .left_content_wrapper {
                                      label {
                                      }
                                    }
                                    .right_content_wrapper {
                                      .lbl_1 {
                                      }
                                      .lbl_2 {
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
