.doubtDetailsView_wrapper {
  .doubtDetailsView_inner_wrapper {
    .sec_1_wrapper {
      margin: 20px 0;
      .sec_1_inner_wrapper {
        display: flex;
        justify-content: flex-start;
        .btn_wrapper {
          button {
            outline: none;
            border: none;
            background: transparent;
          }
        }
        .text_content_wrapper {
          margin: 0 10px;
          .text_content {
            margin: 0;
            font-size: 20px;
            font-weight: 600;
            color: #0f0404;
          }
        }
      }
    }
    .sec_2_wrapper {
      margin: 20px 0;
      border-radius: 8px;
      background-color: #ffffff;
      .sec_2_inner_wrapper {
        .content_wrapper {
          padding: 20px;
          .sub_section_wrapper_1 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .left_side_content_wrapper {
              .text_content_wrapper {
                .text_content {
                  margin: 0;
                  font-size: 14px;
                  color: #6b6876;
                }
              }
            }
            .right_side_content_wrapper {
              .image_wrapper {
                img {
                }
              }
            }
          }
          .sub_section_wrapper_2 {
            margin: 10px 0;
            .text_content_wrapper {
              .text_content {
                margin: 0;
                font-size: 16px;
                font-weight: 500;
                color: #09041a;
              }
            }
          }
          .sub_section_wrapper_3 {
            margin: 10px 0;
            .images_collection_wrapper {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              flex-wrap: wrap;
              .image_wrapper {
                margin: 10px;
                img {
                  border-radius: 10px;
                  width: 350px;
                  height: 250px;
                }
              }
            }
          }
          .sub_section_wrapper_4 {
            margin: 10px 0;
            .text_content_wrapper_1 {
              margin: 5px 0;
              .text_content_1 {
                margin: 0;
                font-size: 14px;
                font-weight: 600;
                color: #3aa02b;
              }
            }
            .text_content_wrapper_2 {
              margin: 5px 0;
              .text_content_2 {
                margin: 0;
                font-size: 14px;
                font-weight: 500;
                color: #6b6876;
              }
            }
          }
        }
      }
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .doubtDetailsView_wrapper {
    .doubtDetailsView_inner_wrapper {
      .sec_1_wrapper {
        .sec_1_inner_wrapper {
          .btn_wrapper {
            button {
            }
          }
          .text_content_wrapper {
            .text_content {
            }
          }
        }
      }
      .sec_2_wrapper {
        .sec_2_inner_wrapper {
          .content_wrapper {
            .sub_section_wrapper_1 {
              .left_side_content_wrapper {
                .text_content_wrapper {
                  .text_content {
                  }
                }
              }
              .right_side_content_wrapper {
                .image_wrapper {
                  img {
                  }
                }
              }
            }
            .sub_section_wrapper_2 {
              .text_content_wrapper {
                .text_content {
                }
              }
            }
            .sub_section_wrapper_3 {
              .images_collection_wrapper {
                .image_wrapper {
                  img {
                    width: 100px;
                    height: 100px;
                  }
                }
              }
            }
            .sub_section_wrapper_4 {
              .text_content_wrapper_1 {
                .text_content_1 {
                }
              }
              .text_content_wrapper_2 {
                .text_content_2 {
                }
              }
            }
          }
        }
      }
    }
  }
}
