.doubtsView_wrapper {
  .doubtsView_inner_wrapper {
    .sec_1_wrapper {
      .sec_1_inner_wrapper {
        .content_wrapper {
          display: flex;
          justify-content: flex-start;
          .content_inner_wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 8px;
            box-shadow: 0 4px 16px 0 rgba(109, 72, 239, 0.08);
            background-color: #ffffff;
            padding: 10px;
            cursor: pointer;
            .image_wrapper {
              img {
                width: 30px;
              }
            }
            .text_content_wrapper {
              margin-left: 5px;
              .text_content {
                margin: 0;
                font-size: 14px;
                font-weight: 500;
                color: #0f0404;
              }
            }
          }
        }
      }
    }
    .sec_2_wrapper {
      margin: 20px 0;
      border-radius: 8px;
      background-color: #ffffff;
      .sec_2_inner_wrapper {
        .content_wrapper {
          padding: 0 10px;
          display: flex;
          justify-content: flex-start;
          padding: 20px 0;
          .left_side_content_wrapper {
            flex: 0 0 30%;
            .subject_collection_wrapper {
              display: flex;
              flex-wrap: wrap;
              flex-direction: column;
              align-items: center;
              .single_select_subject {
                margin: 10px 0;
                input[type="radio"] {
                  opacity: 0;
                  position: fixed;
                  width: 0;
                }
                label {
                  border: solid 1px #f0eef8;
                  background-color: #fafafd;
                  padding: 10px 20px;
                  border-radius: 25px;
                  font-size: 16px;
                  font-weight: 500;
                  letter-spacing: 0.2px;
                  color: #6b6876;
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                }
                input[type="radio"]:checked + label {
                  background-color: #6d48ef;
                  border: none;
                  color: #ffffff;
                }
              }
            }
          }
          .right_side_content_wrapper {
            flex: 0 0 70%;
            .doubtsCollectionView_wrapper {
              .doubtsCollectionView_inner_wrapper {
                .section_wrapper {
                  .section_inner_wrapper {
                    padding: 0 5px;
                    .options_wrapper {
                      .custom_nav_pills {
                        padding: 10px;
                        border-radius: 25px;
                        background-color: #f2f2f3;
                        .custom_nav_item {
                          flex: 0 0 50%;
                          .custom_nav_link {
                            width: 100%;
                            border-radius: 25px;
                            border: none;
                            outline: none;
                            font-size: 16px;
                            font-weight: 500;
                            color: #6b6876;
                          }
                          .custom_nav_link.active {
                            color: #ffffff;
                          }
                        }
                      }
                    }
                    .tab_wrapper {
                      .custom_tab_content {
                        //////answered doubts tab////
                        .custom_tab_pane {
                          .answered_doubts_tab_wrapper {
                            .outer_content_wrapper {
                              .doubts_collection_wrapper {
                                .single_doubt_wrapper {
                                  background-color: #fafafd;
                                  border-radius: 8px;
                                  margin: 10px 0;
                                  padding: 15px;
                                  .content_wrapper_1 {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    .left_side_content_wrapper {
                                      .text_content_wrapper {
                                        .text_content {
                                          margin: 0;
                                          font-size: 14px;
                                          font-weight: 500;
                                          color: #6b6876;
                                        }
                                      }
                                    }
                                    .right_side_content_wrapper {
                                      text-align: right;
                                      .image_wrapper {
                                        img {
                                        }
                                      }
                                    }
                                  }
                                  .content_wrapper_2 {
                                    margin: 10px 0;
                                    .text_content_wrapper {
                                      .text_content {
                                        margin: 0;
                                        font-size: 16px;
                                        font-weight: 500;
                                        color: #09041a;
                                      }
                                    }
                                  }
                                  .content_wrapper_3 {
                                    margin: 10px 0;
                                    .text_content_wrapper {
                                      .text_content {
                                        margin: 0;
                                        font-size: 14px;
                                        font-weight: 600;
                                        color: #3aa02b;
                                        label {
                                          cursor: pointer;
                                          i {
                                            font-size: 14px;
                                            font-weight: 600;
                                            color: #3aa02b;
                                            vertical-align: middle;
                                            margin-left: 5px;
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                        //////unanswered doubts tab////
                        .custom_tab_pane {
                          .unanswered_doubts_tab_wrapper {
                            .outer_content_wrapper {
                              .doubts_collection_wrapper {
                                .single_doubt_wrapper {
                                  background-color: #fafafd;
                                  border-radius: 8px;
                                  margin: 10px 0;
                                  padding: 15px;
                                  .content_wrapper_1 {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    .left_side_content_wrapper {
                                      .text_content_wrapper {
                                        .text_content {
                                          margin: 0;
                                          font-size: 14px;
                                          font-weight: 500;
                                          color: #6b6876;
                                        }
                                      }
                                    }
                                    .right_side_content_wrapper {
                                      text-align: right;
                                      .image_wrapper {
                                        img {
                                        }
                                      }
                                    }
                                  }
                                  .content_wrapper_2 {
                                    margin: 10px 0;
                                    .text_content_wrapper {
                                      .text_content {
                                        margin: 0;
                                        font-size: 16px;
                                        font-weight: 500;
                                        color: #09041a;
                                      }
                                    }
                                  }
                                  .content_wrapper_3 {
                                    margin: 10px 0;
                                    .text_content_wrapper {
                                      .text_content {
                                        margin: 0;
                                        font-size: 14px;
                                        font-weight: 600;
                                        color: #3aa02b;
                                        label {
                                          cursor: pointer;
                                          i {
                                            font-size: 14px;
                                            font-weight: 600;
                                            color: #3aa02b;
                                            vertical-align: middle;
                                            margin-left: 5px;
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.no_data_found_wrapper {
  text-align: center;
  img {
    width: 150px;
    height: 150px;
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .doubtsView_wrapper {
    .doubtsView_inner_wrapper {
      .sec_1_wrapper {
        .sec_1_inner_wrapper {
          .content_wrapper {
            .content_inner_wrapper {
              .image_wrapper {
                img {
                }
              }
              .text_content_wrapper {
                .text_content {
                }
              }
            }
          }
        }
      }
      .sec_2_wrapper {
        .sec_2_inner_wrapper {
          .content_wrapper {
            flex-direction: column;
            flex-wrap: wrap;
            .left_side_content_wrapper {
              flex: 0 0 100%;
              margin-bottom: 10px;
              .subject_collection_wrapper {
                flex-wrap: wrap;
                flex-direction: row;
                .single_select_subject {
                  margin: 5px;
                  input[type="radio"] {
                  }
                  label {
                  }
                  input[type="radio"]:checked + label {
                  }
                }
              }
            }
            .right_side_content_wrapper {
              flex: 0 0 100%;
              .doubtsCollectionView_wrapper {
                .doubtsCollectionView_inner_wrapper {
                  .section_wrapper {
                    .section_inner_wrapper {
                      .options_wrapper {
                        .custom_nav_pills {
                          padding: 5px;
                          .custom_nav_item {
                            .custom_nav_link {
                            }
                            .custom_nav_link.active {
                            }
                          }
                        }
                      }
                      .tab_wrapper {
                        .custom_tab_content {
                          //////answered doubts tab////
                          .custom_tab_pane {
                            .answered_doubts_tab_wrapper {
                              .outer_content_wrapper {
                                .doubts_collection_wrapper {
                                  .single_doubt_wrapper {
                                    .content_wrapper_1 {
                                      .left_side_content_wrapper {
                                        flex: 0 0 30%;
                                        .text_content_wrapper {
                                          .text_content {
                                          }
                                        }
                                      }
                                      .right_side_content_wrapper {
                                        flex: 0 0 70%;
                                        .image_wrapper {
                                          img {
                                          }
                                        }
                                      }
                                    }
                                    .content_wrapper_2 {
                                      .text_content_wrapper {
                                        .text_content {
                                        }
                                      }
                                    }
                                    .content_wrapper_3 {
                                      .text_content_wrapper {
                                        .text_content {
                                          label {
                                            i {
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                          //////unanswered doubts tab////
                          .custom_tab_pane {
                            .unanswered_doubts_tab_wrapper {
                              .outer_content_wrapper {
                                .doubts_collection_wrapper {
                                  .single_doubt_wrapper {
                                    .content_wrapper_1 {
                                      .left_side_content_wrapper {
                                        flex: 0 0 30%;
                                        .text_content_wrapper {
                                          .text_content {
                                          }
                                        }
                                      }
                                      .right_side_content_wrapper {
                                        flex: 0 0 70%;
                                        .image_wrapper {
                                          img {
                                          }
                                        }
                                      }
                                    }
                                    .content_wrapper_2 {
                                      .text_content_wrapper {
                                        .text_content {
                                        }
                                      }
                                    }
                                    .content_wrapper_3 {
                                      .text_content_wrapper {
                                        .text_content {
                                          label {
                                            i {
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
