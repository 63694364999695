.mobileHeaderComponent_wrapper {
  width: 100%;
  background-color: #ffffff;
  padding: 10px 0;
  position: fixed;
  z-index: 99;
  .mobileHeaderComponent_inner_wrapper {
    .content_wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .search_bar_wrapper {
        padding: 0 5px;
        .search_bar_inner_wrapper {
          background: #ffffff;
          border-radius: 5px;
          // padding: 0 10px;
          .content_wrapper {
            form {
              display: flex;
              align-items: center;
              .search_image {
                margin: 0 10px;
              }
              .search_input {
                width: 100%;
                height: 40px;
                outline: none;
                border: none;
              }
              ::placeholder {
                color: #9898a0;
              }
            }
          }
        }
      }
      .course_dropdown_wrapper {
        padding: 0 5px;
        .course_dropdown_inner_wrapper {
          background: #ffffff;
          border-radius: 5px;
          // width: 200px;
          padding: 0 10px;
          .content_wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
            cursor: pointer;
            .course_image {
              margin-right: 10px;
            }
            .text_content {
              margin: 0;
              font-weight: 600;
              color: #000000;
            }
            .caret_dropdown_icon {
              margin: 0 5px;
              i {
                color: #000000;
              }
            }
          }
        }
      }
    }
  }
}
