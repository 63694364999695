.graphStatistics_wrapper {
  .graphStatistics_inner_wrapper {
    .text_content_wrapper {
      .text_content {
        margin: 0;
        font-size: 20px;
        font-weight: 600;
        color: #0f0404;
      }
    }
    .graph_collection_wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .single_graph_wrapper {
        flex: 0 0 30%;
        border-radius: 8px;
        background: linear-gradient(to top, #ffffff, #fafafd);
      }
    }
  }
}

// X-Large devices (large desktops, less than 1400px)
@media (max-width: 1199.98px) {
  .graphStatistics_wrapper {
    .graphStatistics_inner_wrapper {
      .text_content_wrapper {
        .text_content {
        }
      }
      .graph_collection_wrapper {
        flex-wrap: wrap;
        .single_graph_wrapper {
          flex: 0 0 45%;
          margin: 5px;
        }
      }
    }
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .graphStatistics_wrapper {
    .graphStatistics_inner_wrapper {
      .text_content_wrapper {
        .text_content {
        }
      }
      .graph_collection_wrapper {
        .single_graph_wrapper {
          flex: 0 0 100%;
        }
      }
    }
  }
}
