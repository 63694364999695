.package_offering_component_wrapper {
  min-height: 100vh;
  margin: 10px 0;
  .package_offering_component_inner_wrapper {
    padding: 20px 0;
    .sec_1_wrapper {
      margin: 10px 0;
      .text_content_wrapper {
        .text_content {
          margin: 0;
          font-size: 20px;
          font-weight: 600;
          color: #000000;
        }
      }
    }
    .sec_2_wrapper {
      margin: 25px 0;
      .package_collection_wrapper {
        .package_collection {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          .single_package_wrapper {
            flex: 0 0 30%;
            margin: 10px;
            .single_package_inner_wrapper {
              .sec_1_wrapper {
                background-color: #a02b2d;
                border-radius: 20px 20px 0 0;
                padding: 10px;
                margin: 0;
                .image_wrapper {
                  text-align: right;
                  img {
                    width: 20px;
                  }
                }
                .text_content_wrapper_1 {
                  .text_content_1 {
                    margin: 0;
                    font-size: 20px;
                    font-weight: 600;
                    color: #ffffff;
                    text-align: center;
                  }
                }
                .text_content_wrapper_2 {
                  .text_content_2 {
                    margin: 0;
                    font-size: 18px;
                    font-weight: 600;
                    color: #ffffff;
                    text-align: center;
                  }
                }
                .text_content_wrapper_3 {
                  .text_content_3 {
                    margin: 0;
                    font-size: 16px;
                    font-weight: 500;
                    color: #ffffff;
                    text-align: center;
                  }
                }
              }
              .sec_2_wrapper {
                background-color: #d6d0ed45;
                border-radius: 0 0 20px 20px;
                padding: 10px;
                margin: 0;
                .content_wrapper_1 {
                  .text_content_wrapper {
                    .text_content {
                      margin: 0;
                      font-size: 16px;
                      font-weight: 600;
                      color: #000000;
                      text-align: center;
                    }
                  }
                  .module_collection_wrapper {
                    .module_collection {
                      .text_content_wrapper {
                        .text_content {
                          margin: 0;
                          font-size: 16px;
                          color: #6b6876;
                          text-align: center;
                        }
                      }
                    }
                  }
                }
                .content_wrapper_2 {
                  margin: 10px 0;
                  .btn_wrapper_1 {
                    text-align: center;
                    button {
                      border: none;
                      outline: none;
                      background-color: #6d48ef;
                      font-size: 18px;
                      font-weight: 600;
                      color: #ffffff;
                      border-radius: 25px;
                      padding: 10px 15px;
                      width: 80%;
                    }
                  }
                  .text_content_wrapper_1 {
                    margin: 10px 0;
                    .text_content_1 {
                      margin: 0;
                      font-size: 16px;
                      font-weight: 600;
                      text-align: center;
                      color: #000000;
                    }
                  }
                  .text_content_wrapper_2 {
                    margin: 10px 0;
                    .text_content_2 {
                      margin: 0;
                      font-size: 16px;
                      font-weight: 600;
                      text-align: center;
                      color: #a02b2d;
                    }
                  }
                  .btn_wrapper_2 {
                    text-align: center;
                    button {
                      border: none;
                      outline: none;
                      background-color: transparent;
                      font-size: 18px;
                      font-weight: 600;
                      text-decoration: underline;
                      color: #6d48ef;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .package_offering_component_wrapper {
    min-height: auto;
    margin: 5px 0;
    .package_offering_component_inner_wrapper {
      padding: 10px 0;
      .sec_1_wrapper {
        margin: 5px 0;
        .text_content_wrapper {
          .text_content {
          }
        }
      }
      .sec_2_wrapper {
        margin: 5px 0;
        .package_collection_wrapper {
          .package_collection {
            .single_package_wrapper {
              flex: 0 0 95%;
              .single_package_inner_wrapper {
                .sec_1_wrapper {
                  .text_content_wrapper_1 {
                    .text_content_1 {
                    }
                  }
                  .text_content_wrapper_2 {
                    .text_content_2 {
                    }
                  }
                }
                .sec_2_wrapper {
                  .content_wrapper_1 {
                    .text_content_wrapper {
                      .text_content {
                      }
                    }
                    .module_collection_wrapper {
                      .module_collection {
                        .text_content_wrapper {
                          .text_content {
                          }
                        }
                      }
                    }
                  }
                  .content_wrapper_2 {
                    .btn_wrapper_1 {
                      button {
                      }
                    }
                    .text_content_wrapper {
                      .text_content {
                      }
                    }
                    .btn_wrapper_2 {
                      button {
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
