.subjectContentComponent_wrapper {
  padding-top: 80px;
  .subjectContentComponent_inner_wrapper {
    padding: 20px;
    .sec_1_wrapper {
      margin: 20px 0;
      .sec_1_inner_wrapper {
        display: flex;
        justify-content: flex-start;
        .btn_wrapper {
          button {
            outline: none;
            border: none;
            background: transparent;
          }
        }
        .text_content_wrapper {
          margin: 0 10px;
          .text_content {
            margin: 0;
            font-size: 20px;
            font-weight: 600;
            color: #0f0404;
          }
        }
      }
    }
    .sec_2_wrapper {
      margin: 20px 0;
      border-radius: 8px;
      background-color: #ffffff;
      .sec_2_inner_wrapper {
        .content_wrapper {
          padding: 0 10px;
          display: flex;
          justify-content: flex-start;
          padding: 20px 0;
          .left_side_content_wrapper {
            flex: 0 0 30%;
            .subject_collection_wrapper {
              display: flex;
              flex-wrap: wrap;
              flex-direction: column;
              align-items: center;
              .single_select_subject {
                margin: 10px 0;
                input[type="radio"] {
                  opacity: 0;
                  position: fixed;
                  width: 0;
                }
                label {
                  border: solid 1px #f0eef8;
                  background-color: #fafafd;
                  padding: 10px 20px;
                  border-radius: 25px;
                  font-size: 16px;
                  font-weight: 500;
                  letter-spacing: 0.2px;
                  color: #6b6876;
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                }
                input[type="radio"]:checked + label {
                  background-color: #6d48ef;
                  border: none;
                  color: #ffffff;
                }
              }
            }
          }
          .right_side_content_wrapper {
            flex: 0 0 70%;
            .topic_collection_wrapper {
              display: flex;
              flex-direction: column;
              border-left: 1px solid #00000017;
              padding: 0 20px;
              height: 450px;
              overflow-y: scroll;
              .single_topic_wrapper {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                border-radius: 15px;
                border: solid 1px #f0eef8;
                background-color: #fafafd;
                padding: 10px;
                margin: 10px 0;
                cursor: pointer;
                .serial_number_wrapper {
                  border-radius: 15px;
                  background-image: linear-gradient(
                    149deg,
                    #fff5e6 32%,
                    #ffefe6 76%
                  );
                  padding: 15px;
                  .text_content_wrapper {
                    .text_content {
                      margin: 0;
                      font-size: 20px;
                      font-weight: 600;
                      text-align: center;
                      color: #b35557;
                    }
                  }
                }
                .topic_description_wrapper {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  width: 100%;
                  padding: 0 10px;
                  .topic_details_wrapper {
                    .text_content_wrapper {
                      .text_content {
                        margin: 0;
                        font-size: 16px;
                        font-weight: 500;
                        color: #0f0404;
                      }
                    }
                  }
                  .caret_wraper {
                    .text_content_wrapper {
                      .text_content {
                        margin: 0;
                        i {
                          vertical-align: middle;
                        }
                      }
                    }
                  }
                }
              }
            }
            .topic_collection_wrapper::-webkit-scrollbar {
              width: 7px;
            }
            .topic_collection_wrapper::-webkit-scrollbar-track {
              box-shadow: inset 0 0 5px grey;
              border-radius: 10px;
            }

            /* Handle */
            .topic_collection_wrapper::-webkit-scrollbar-thumb {
              background: #6d48ef;
              border-radius: 10px;
            }

            /* Handle on hover */
            .topic_collection_wrapper::-webkit-scrollbar-thumb:hover {
              background: #6d48ef;
            }
          }
        }
      }
    }
  }
}

.no_data_found_wrapper {
  text-align: center;
  img {
    width: 150px;
    height: 150px;
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .subjectContentComponent_wrapper {
    padding-top: 60px;
    .subjectContentComponent_inner_wrapper {
      padding: 20px 10px;
      .sec_1_wrapper {
        margin: 10px 0;
        .sec_1_inner_wrapper {
          .btn_wrapper {
            button {
            }
          }
          .text_content_wrapper {
            .text_content {
            }
          }
        }
      }
      .sec_2_wrapper {
        margin: 10px 0;
        .sec_2_inner_wrapper {
          .content_wrapper {
            padding: 10px 0;
            flex-wrap: wrap;
            .left_side_content_wrapper {
              flex: 0 0 100%;
              padding: 5px 0;
              margin: 5px 0;
              .subject_collection_wrapper {
                padding: 0 10px;
                flex-direction: row;
                .single_select_subject {
                  margin: 5px;
                  input[type="radio"] {
                  }
                  label {
                  }
                  input[type="radio"]:checked + label {
                  }
                }
              }
            }
            .right_side_content_wrapper {
              flex: 0 0 100%;
              padding: 5px 0;
              margin: 5px 0;
              .topic_collection_wrapper {
                border-left: none;
                border-top: 1px solid #00000017;
                padding: 0 10px;
                .single_topic_wrapper {
                  .serial_number_wrapper {
                    .text_content_wrapper {
                      .text_content {
                      }
                    }
                  }
                  .topic_description_wrapper {
                    .topic_details_wrapper {
                      .text_content_wrapper {
                        .text_content {
                        }
                      }
                    }
                    .caret_wraper {
                      .text_content_wrapper {
                        .text_content {
                          i {
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
