#zmmtg-root {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  display: none;
  z-index: 9999;
  overflow: auto;
}

.meeting-info-container{
  display: none ! important;
}

.topicContentComponent_wrapper {
  padding-top: 80px;
  .topicContentComponent_inner_wrapper {
    padding: 20px;
    .sec_1_wrapper {
      margin: 20px 0;
      .sec_1_inner_wrapper {
        display: flex;
        justify-content: flex-start;
        .btn_wrapper {
          button {
            outline: none;
            border: none;
            background: transparent;
          }
        }
        .text_content_wrapper {
          margin: 0 10px;
          .text_content {
            margin: 0;
            font-size: 20px;
            font-weight: 600;
            color: #0f0404;
          }
        }
      }
    }
    .sec_2_wrapper {
      margin: 20px 0;
      border-radius: 8px;
      background-color: #ffffff;
      .sec_2_inner_wrapper {
        .content_wrapper {
          padding: 0 10px;
          display: flex;
          justify-content: flex-start;
          padding: 20px 0;
          .left_side_content_wrapper {
            flex: 0 0 30%;
            .feature_collection_wrapper {
              display: flex;
              flex-wrap: wrap;
              flex-direction: column;
              align-items: center;
              .single_select_feature {
                margin: 10px 0;
                input[type="radio"] {
                  opacity: 0;
                  position: fixed;
                  width: 0;
                }
                label {
                  border: solid 1px #f0eef8;
                  background-color: #fafafd;
                  padding: 10px 20px;
                  border-radius: 25px;
                  font-size: 16px;
                  font-weight: 500;
                  letter-spacing: 0.2px;
                  color: #6b6876;
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                }
                input[type="radio"]:checked + label {
                  background-color: #6d48ef;
                  border: none;
                  color: #ffffff;
                }
              }
            }
          }
          .right_side_content_wrapper {
            flex: 0 0 70%;
            padding: 0 10px;
            ////////////////////videos/////////////////
            .videosCollectionView_wrapper {
              .videosCollectionView_inner_wrapper {
                .sec_1_wrapper {
                  text-align: right;
                  margin: 0;
                  .select_wrapper {
                    select {
                      width: auto;
                      height: 45px;
                      border-radius: 25px;
                      border: 2px solid #6d48ef;
                      background-color: #ffffff;
                      font-size: 16px;
                      font-weight: 600;
                      text-align: center;
                      color: #6d48ef;
                      padding: 0 10px;
                    }
                  }
                }
                .sec_2_wrapper {
                  margin: 10px 0;
                  .sec_2_inner_wrapper {
                    .videos_collection_wrapper {
                      display: flex;
                      flex-direction: column;
                      height: 450px;
                      overflow-y: scroll;
                      .single_video_wrapper {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        border-radius: 8px;
                        background-color: #faf9fe;
                        padding: 10px;
                        margin: 10px 0;
                        cursor: pointer;
                        .left_side_content_wrapper {
                          width: auto;
                          flex: auto;
                          .image_wrapper {
                            text-align: center;
                            img {
                              width: 100px;
                            }
                          }
                        }
                        .right_side_content_wrapper {
                          flex: auto;
                          margin: 0 10px;
                          width: 100%;
                          .text_content_wrapper_1 {
                            .text_content_1 {
                              margin: 0;
                              font-size: 16px;
                              font-weight: 600;
                              color: #0f0404;
                            }
                          }
                          .text_content_wrapper_2 {
                            .text_content_2 {
                              margin: 0;
                              font-size: 14px;
                              font-weight: 500;
                              color: #6f6868;
                            }
                          }
                          .sub_content_wrapper {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 100%;
                            .left_side_content_wrapper {
                              // flex: 0 0 50%;
                              .label_content_wrapper {
                                label {
                                  padding: 5px 10px;
                                  border-radius: 15px;
                                  background-color: #fef4ec;
                                  span {
                                    img {
                                      width: 15px;
                                      height: 15px;
                                    }
                                  }
                                }
                              }
                            }
                            .right_side_content_wrapper {
                              // flex: 0 0 50%;
                              text-align: right;
                              margin: 0;
                              padding: 0;
                              width: auto;
                              .subject_name {
                                padding: 5px;
                                border-radius: 5px;
                                background-image: linear-gradient(
                                  120deg,
                                  #ff9600 37%,
                                  #ff5d00 72%
                                );
                                font-size: 12px;
                                font-weight: 600;
                                color: #ffffff;
                              }
                            }
                          }
                        }
                      }
                    }
                    .videos_collection_wrapper::-webkit-scrollbar {
                      width: 7px;
                    }
                    .videos_collection_wrapper::-webkit-scrollbar-track {
                      box-shadow: inset 0 0 5px grey;
                      border-radius: 10px;
                    }

                    /* Handle */
                    .videos_collection_wrapper::-webkit-scrollbar-thumb {
                      background: #6d48ef;
                      border-radius: 10px;
                    }

                    /* Handle on hover */
                    .videos_collection_wrapper::-webkit-scrollbar-thumb:hover {
                      background: #6d48ef;
                    }
                  }
                }
                .sec_3_wrapper {
                  margin: 10px 0;
                  .sec_3_inner_wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .btn_wrapper {
                      button {
                        border: none;
                        outline: none;
                        width: auto;
                        height: 45px;
                        border-radius: 5px;
                        border: 2px solid #6d48ef;
                        background-color: #6d48ef;
                        font-size: 16px;
                        font-weight: 600;
                        text-align: center;
                        color: #ffffff;
                        padding: 0 10px;
                      }
                    }
                  }
                }
              }
            }
            //////////////live class/////////////////
            .liveClassCollectionView_wrapper {
              .liveClassCollectionView_inner_wrapper {
                .section_wrapper {
                  .section_inner_wrapper {
                    .options_wrapper {
                      .custom_nav_pills {
                        padding: 10px;
                        border-radius: 25px;
                        background-color: #f2f2f3;
                        .custom_nav_item {
                          flex: 0 0 50%;
                          .custom_nav_link {
                            width: 100%;
                            border-radius: 25px;
                            border: none;
                            outline: none;
                            font-size: 16px;
                            font-weight: 500;
                            color: #6b6876;
                          }
                          .custom_nav_link.active {
                            color: #ffffff;
                          }
                        }
                      }
                    }
                    .tab_wrapper {
                      .custom_tab_content {
                        //////upcoming class tab////
                        .custom_tab_pane {
                          .upcoming_class_tab_wrapper {
                            .outer_content_wrapper {
                              .liveClasses_collection_wrapper {
                                display: flex;
                                flex-direction: column;
                                .single_liveClass_wrapper {
                                  width: 100%;
                                  display: flex;
                                  align-items: center;
                                  justify-content: flex-start;
                                  border-radius: 8px;
                                  background-color: #faf9fe;
                                  padding: 10px;
                                  margin: 10px 0;

                                  .left_side_content_wrapper {
                                    width: auto;
                                    flex: auto;
                                    .image_wrapper {
                                      text-align: center;
                                      img {
                                      }
                                    }
                                  }
                                  .right_side_content_wrapper {
                                    flex: auto;
                                    margin: 0 10px;
                                    width: 100%;
                                    .text_content_wrapper_1 {
                                      .text_content_1 {
                                        margin: 0;
                                        font-size: 16px;
                                        font-weight: 600;
                                        color: #0f0404;
                                      }
                                    }
                                    .text_content_wrapper_2 {
                                      .text_content_2 {
                                        margin: 0;
                                        font-size: 14px;
                                        font-weight: 500;
                                        color: #6f6868;
                                      }
                                    }
                                    .sub_content_wrapper {
                                      display: flex;
                                      align-items: center;
                                      justify-content: space-between;
                                      width: 100%;
                                      .left_side_content_wrapper {
                                        // flex: 0 0 50%;
                                        .label_content_wrapper {
                                          label {
                                            padding: 5px 10px;
                                            border-radius: 15px;
                                            // background-color: #fef4ec;
                                            span {
                                              img {
                                                width: 15px;
                                                height: 15px;
                                              }
                                            }
                                          }
                                        }
                                      }
                                      .right_side_content_wrapper {
                                        text-align: right;
                                        margin: 0;
                                        padding: 0;
                                        width: auto;
                                        .text_content_wrapper {
                                          .text_content {
                                            margin: 0;
                                            font-size: 14px;
                                            font-weight: 600;
                                            text-decoration: underline;
                                            color: #6d48ef;
                                            cursor: pointer;
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                              .sec_3_wrapper {
                                margin: 10px 0;
                                .sec_3_inner_wrapper {
                                  display: flex;
                                  justify-content: space-between;
                                  align-items: center;
                                  .btn_wrapper {
                                    button {
                                      border: none;
                                      outline: none;
                                      width: auto;
                                      height: 45px;
                                      border-radius: 5px;
                                      border: 2px solid #6d48ef;
                                      background-color: #6d48ef;
                                      font-size: 16px;
                                      font-weight: 600;
                                      text-align: center;
                                      color: #ffffff;
                                      padding: 0 10px;
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                        //////previous class tab////
                        .custom_tab_pane {
                          .previous_class_tab_wrapper {
                            .outer_content_wrapper {
                              .liveClasses_collection_wrapper {
                                display: flex;
                                flex-direction: column;
                                .single_liveClass_wrapper {
                                  width: 100%;
                                  display: flex;
                                  align-items: center;
                                  justify-content: flex-start;
                                  border-radius: 8px;
                                  background-color: #faf9fe;
                                  padding: 10px;
                                  margin: 10px 0;
                                  .left_side_content_wrapper {
                                    width: auto;
                                    flex: auto;
                                    .image_wrapper {
                                      text-align: center;
                                      img {
                                      }
                                    }
                                  }
                                  .right_side_content_wrapper {
                                    flex: auto;
                                    margin: 0 10px;
                                    width: 100%;
                                    .text_content_wrapper_1 {
                                      .text_content_1 {
                                        margin: 0;
                                        font-size: 16px;
                                        font-weight: 600;
                                        color: #0f0404;
                                      }
                                    }
                                    .text_content_wrapper_2 {
                                      .text_content_2 {
                                        margin: 0;
                                        font-size: 14px;
                                        font-weight: 500;
                                        color: #6f6868;
                                      }
                                    }
                                    .sub_content_wrapper {
                                      display: flex;
                                      align-items: center;
                                      justify-content: space-between;
                                      width: 100%;
                                      .left_side_content_wrapper {
                                        // flex: 0 0 50%;
                                        .label_content_wrapper {
                                          label {
                                            padding: 5px 10px;
                                            border-radius: 15px;
                                            // background-color: #fef4ec;
                                            span {
                                              img {
                                                width: 15px;
                                                height: 15px;
                                              }
                                            }
                                          }
                                        }
                                      }
                                      .right_side_content_wrapper {
                                        // flex: 0 0 50%;
                                        text-align: right;
                                        margin: 0;
                                        padding: 0;
                                        width: auto;
                                        .text_content_wrapper {
                                          .text_content {
                                            margin: 0;
                                            font-size: 14px;
                                            font-weight: 600;
                                            text-decoration: underline;
                                            color: #6d48ef;
                                            cursor: pointer;
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                              .sec_3_wrapper {
                                margin: 10px 0;
                                .sec_3_inner_wrapper {
                                  display: flex;
                                  justify-content: space-between;
                                  align-items: center;
                                  .btn_wrapper {
                                    button {
                                      border: none;
                                      outline: none;
                                      width: auto;
                                      height: 45px;
                                      border-radius: 5px;
                                      border: 2px solid #6d48ef;
                                      background-color: #6d48ef;
                                      font-size: 16px;
                                      font-weight: 600;
                                      text-align: center;
                                      color: #ffffff;
                                      padding: 0 10px;
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            /////////exercise////////////////
            .exerciseCollectionView_wrapper {
              .exerciseCollectionView_inner_wrapper {
                .sec_1_wrapper {
                  .sec_1_inner_wrapper {
                    .exercise_collection_wrapper {
                      width: 100%;
                      display: flex;
                      flex-direction: column;
                      height: 450px;
                      overflow-y: scroll;
                      padding: 10px;
                      .single_exercise_wrapper {
                        width: 100%;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        border-radius: 15px;
                        border: solid 1px #f0eef8;
                        background-color: #fafafd;
                        padding: 10px;
                        margin: 10px 0;
                        cursor: pointer;
                        .serial_number_wrapper {
                          border-radius: 15px;
                          background-image: linear-gradient(
                            149deg,
                            #fff5e6 32%,
                            #ffefe6 76%
                          );
                          padding: 15px;
                          .text_content_wrapper {
                            .text_content {
                              margin: 0;
                              font-size: 20px;
                              font-weight: 600;
                              text-align: center;
                              color: #b35557;
                            }
                          }
                        }
                        .exercise_description_wrapper {
                          display: flex;
                          align-items: center;
                          justify-content: space-between;
                          width: 100%;
                          padding: 0 10px;
                          .exercise_details_wrapper {
                            .text_content_wrapper_1 {
                              .text_content_1 {
                                margin: 0;
                                font-size: 16px;
                                font-weight: 500;
                                color: #0f0404;
                              }
                            }
                            .text_content_wrapper_2 {
                              .text_content_2 {
                                margin: 0;
                                font-size: 14px;
                                font-weight: 500;
                                color: #6b6876;
                              }
                            }
                          }
                          .caret_wraper {
                            .text_content_wrapper {
                              .text_content {
                                margin: 0;
                                i {
                                  vertical-align: middle;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    .exercise_collection_wrapper::-webkit-scrollbar {
                      width: 7px;
                    }
                    .exercise_collection_wrapper::-webkit-scrollbar-track {
                      box-shadow: inset 0 0 5px grey;
                      border-radius: 10px;
                    }

                    /* Handle */
                    .exercise_collection_wrapper::-webkit-scrollbar-thumb {
                      background: #6d48ef;
                      border-radius: 10px;
                    }

                    /* Handle on hover */
                    .exercise_collection_wrapper::-webkit-scrollbar-thumb:hover {
                      background: #6d48ef;
                    }
                  }
                }
                .sec_2_wrapper {
                  .sec_2_inner_wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .btn_wrapper {
                      button {
                        border: none;
                        outline: none;
                        width: auto;
                        height: 45px;
                        border-radius: 5px;
                        border: 2px solid #6d48ef;
                        background-color: #6d48ef;
                        font-size: 16px;
                        font-weight: 600;
                        text-align: center;
                        color: #ffffff;
                        padding: 0 10px;
                      }
                    }
                  }
                }
              }
            }
            ////////////notes///////////////
            .notesCollectionView_wrapper {
              .notesCollectionView_inner_wrapper {
                .notes_collection_wrapper {
                  display: flex;
                  flex-direction: column;
                  height: 450px;
                  overflow-y: scroll;
                  .single_notes_wrapper {
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    border-radius: 15px;
                    border: solid 1px #f0eef8;
                    background-color: #fafafd;
                    padding: 10px;
                    margin: 10px 0;
                    cursor: pointer;
                    .serial_number_wrapper {
                      border-radius: 15px;
                      background-image: linear-gradient(
                        149deg,
                        #fff5e6 32%,
                        #ffefe6 76%
                      );
                      padding: 15px;
                      .image_wrapper {
                        img {
                        }
                      }
                    }
                    .notes_description_wrapper {
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      width: 100%;
                      padding: 0 10px;
                      .notes_details_wrapper {
                        .text_content_wrapper_1 {
                          .text_content_1 {
                            margin: 0;
                            font-size: 16px;
                            font-weight: 500;
                            color: #0f0404;
                          }
                        }
                        .text_content_wrapper_2 {
                          .text_content_2 {
                            margin: 0;
                            font-size: 14px;
                            font-weight: 500;
                            color: #6b6876;
                          }
                        }
                      }
                      .caret_wraper {
                        .image_wrapper {
                          img {
                          }
                        }
                      }
                    }
                  }
                }
                .notes_collection_wrapper::-webkit-scrollbar {
                  width: 7px;
                }
                .notes_collection_wrapper::-webkit-scrollbar-track {
                  box-shadow: inset 0 0 5px grey;
                  border-radius: 10px;
                }

                /* Handle */
                .notes_collection_wrapper::-webkit-scrollbar-thumb {
                  background: #6d48ef;
                  border-radius: 10px;
                }

                /* Handle on hover */
                .notes_collection_wrapper::-webkit-scrollbar-thumb:hover {
                  background: #6d48ef;
                }
                .sec_3_wrapper {
                  margin: 10px 0;
                  .sec_3_inner_wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .btn_wrapper {
                      button {
                        border: none;
                        outline: none;
                        width: auto;
                        height: 45px;
                        border-radius: 5px;
                        border: 2px solid #6d48ef;
                        background-color: #6d48ef;
                        font-size: 16px;
                        font-weight: 600;
                        text-align: center;
                        color: #ffffff;
                        padding: 0 10px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.no_data_found_wrapper {
  text-align: center;
  img {
    width: 150px;
    height: 150px;
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .topicContentComponent_wrapper {
    padding-top: 60px;
    .topicContentComponent_inner_wrapper {
      padding: 20px 10px;
      .sec_1_wrapper {
        margin: 10px 0;
        .sec_1_inner_wrapper {
          .btn_wrapper {
            button {
            }
          }
          .text_content_wrapper {
            .text_content {
            }
          }
        }
      }
      .sec_2_wrapper {
        margin: 10px 0;
        .sec_2_inner_wrapper {
          .content_wrapper {
            flex-wrap: wrap;
            padding: 10px 0;
            .left_side_content_wrapper {
              flex: 0 0 100%;
              margin: 5px 0;
              .feature_collection_wrapper {
                flex-direction: row;
                justify-content: center;
                .single_select_feature {
                  margin: 5px;
                  flex: 0 0 45%;
                  input[type="radio"] {
                  }
                  label {
                    display: block;
                    align-items: unset;
                    width: 100%;
                    text-align: center;
                  }
                  input[type="radio"]:checked + label {
                  }
                }
              }
            }
            .right_side_content_wrapper {
              flex: 0 0 100%;
              margin: 5px 0;
              border-top: 1px solid #00000017;
              ////////////////////videos/////////////////
              .videosCollectionView_wrapper {
                .videosCollectionView_inner_wrapper {
                  .sec_1_wrapper {
                    .select_wrapper {
                      select {
                      }
                    }
                  }
                  .sec_2_wrapper {
                    .sec_2_inner_wrapper {
                      .videos_collection_wrapper {
                        .single_video_wrapper {
                          .left_side_content_wrapper {
                            .image_wrapper {
                              img {
                              }
                            }
                          }
                          .right_side_content_wrapper {
                            .text_content_wrapper_1 {
                              .text_content_1 {
                              }
                            }
                            .text_content_wrapper_2 {
                              .text_content_2 {
                              }
                            }
                            .sub_content_wrapper {
                              .left_side_content_wrapper {
                                // flex: 0 0 50%;
                                .label_content_wrapper {
                                  label {
                                    span {
                                      img {
                                      }
                                    }
                                  }
                                }
                              }
                              .right_side_content_wrapper {
                                .subject_name {
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              //////////////live class/////////////////
              .liveClassCollectionView_wrapper {
                .liveClassCollectionView_inner_wrapper {
                  .section_wrapper {
                    .section_inner_wrapper {
                      .options_wrapper {
                        .custom_nav_pills {
                          .custom_nav_item {
                            .custom_nav_link {
                            }
                            .custom_nav_link.active {
                            }
                          }
                        }
                      }
                      .tab_wrapper {
                        .custom_tab_content {
                          //////upcoming class tab////
                          .custom_tab_pane {
                            .upcoming_class_tab_wrapper {
                              .outer_content_wrapper {
                                .liveClasses_collection_wrapper {
                                  .single_liveClass_wrapper {
                                    .left_side_content_wrapper {
                                      .image_wrapper {
                                        img {
                                        }
                                      }
                                    }
                                    .right_side_content_wrapper {
                                      .text_content_wrapper_1 {
                                        .text_content_1 {
                                        }
                                      }
                                      .text_content_wrapper_2 {
                                        .text_content_2 {
                                        }
                                      }
                                      .sub_content_wrapper {
                                        .left_side_content_wrapper {
                                          .label_content_wrapper {
                                            label {
                                              span {
                                                img {
                                                }
                                              }
                                            }
                                          }
                                        }
                                        .right_side_content_wrapper {
                                          .text_content_wrapper {
                                            .text_content {
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                          //////previous class tab////
                          .custom_tab_pane {
                            .previous_class_tab_wrapper {
                              .outer_content_wrapper {
                                .liveClasses_collection_wrapper {
                                  .single_liveClass_wrapper {
                                    .left_side_content_wrapper {
                                      .image_wrapper {
                                        img {
                                        }
                                      }
                                    }
                                    .right_side_content_wrapper {
                                      .text_content_wrapper_1 {
                                        .text_content_1 {
                                        }
                                      }
                                      .text_content_wrapper_2 {
                                        .text_content_2 {
                                        }
                                      }
                                      .sub_content_wrapper {
                                        .left_side_content_wrapper {
                                          .label_content_wrapper {
                                            label {
                                              span {
                                                img {
                                                }
                                              }
                                            }
                                          }
                                        }
                                        .right_side_content_wrapper {
                                          .text_content_wrapper {
                                            .text_content {
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              /////////exercise////////////////
              .exerciseCollectionView_wrapper {
                .exerciseCollectionView_inner_wrapper {
                  .exercise_collection_wrapper {
                    .single_exercise_wrapper {
                      .serial_number_wrapper {
                        .text_content_wrapper {
                          .text_content {
                          }
                        }
                      }
                      .exercise_description_wrapper {
                        .exercise_details_wrapper {
                          .text_content_wrapper_1 {
                            .text_content_1 {
                            }
                          }
                          .text_content_wrapper_2 {
                            .text_content_2 {
                            }
                          }
                        }
                        .caret_wraper {
                          .text_content_wrapper {
                            .text_content {
                              i {
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ////////////notes///////////////
              .notesCollectionView_wrapper {
                .notesCollectionView_inner_wrapper {
                  .notes_collection_wrapper {
                    .single_notes_wrapper {
                      .serial_number_wrapper {
                        .image_wrapper {
                          img {
                          }
                        }
                      }
                      .notes_description_wrapper {
                        .notes_details_wrapper {
                          .text_content_wrapper_1 {
                            .text_content_1 {
                            }
                          }
                          .text_content_wrapper_2 {
                            .text_content_2 {
                            }
                          }
                        }
                        .caret_wraper {
                          .image_wrapper {
                            img {
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
