.signUp_step_one_wrapper {
  background-color: #fafafd;
  .signUp_step_one_inner_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
    .content_wrapper {
      .content_inner_wrapper {
        width: 580px;
        border-radius: 8px;
        border: solid 1px #f2f2f3;
        background-color: #ffffff;
        padding: 60px;
        .text_content_wrapper {
          margin: 10px 0;
          .text_content {
            font-size: 32px;
            font-weight: bold;
            line-height: 1.25;
            text-align: center;
            color: #a02b2d;
          }
        }
        .image_content_wrapper {
          display: flex;
          justify-content: center;
          .image_wrapper {
            width: 90px;
            height: 90px;
            border-radius: 50%;
            .image_inner_wrapper {
              width: 70px;
              height: 70px;
              border-radius: 50%;
              background: #eceff4;
              margin: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
              .dummy_image {
                width: 70px;
                height: 70px;
                border-radius: 50%;
              }
              .user_image {
                width: 70px;
                height: 70px;
                border-radius: 50%;
              }
              label {
                position: absolute;
                bottom: 0;
                right: 0;
                cursor: pointer;
                input[type="file"] {
                  display: none;
                }
                img {
                  width: 100%;
                }
              }
            }
          }
        }
        .input_wrapper {
          margin: 15px 0;
          input[type="text"],
          input[type="date"],
          input[type="email"] {
            width: 100%;
            height: 50px;
            border-radius: 25px;
            border: solid 1px #f2f2f3;
            background-color: #faf9fe;
            padding: 0 10px;
            ::placeholder {
              font-size: 16px;
              font-weight: 500;
              line-height: 1.5;
            }
          }
        }
        .gender_wrapper {
          .text_content_wrapper {
            margin: 10px 0;
            .text_content {
              margin: 0;
              font-size: 15px;
              font-weight: 600;
              line-height: 1.33;
              text-align: left;
              color: #0f0404;
            }
          }
          .radio_collection_wrapper {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            .single_select {
              input[type="radio"] {
                opacity: 0;
                position: fixed;
                width: 0;
              }
              label {
                border: solid 1px #f0eef8;
                background-color: #fafafd;
                padding: 10px 20px;
                border-radius: 25px;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.2px;
                color: #6b6876;
                cursor: pointer;
                display: flex;
                align-items: center;
                img {
                  margin-right: 5px;
                }
              }
              input[type="radio"]:checked + label {
                background-color: #6d48ef;
                border: none;
                color: #ffffff;
              }
            }
          }
        }
        .select_wrapper {
          margin: 15px 0;
          select {
            width: 100%;
            height: 50px;
            border-radius: 25px;
            border: solid 1px #f2f2f3;
            background-color: #faf9fe;
            padding: 0 10px;
          }
        }
        .btn_wrapper {
          margin: 20px 0;
          text-align: center;
          button {
            border: none;
            outline: none;
            width: 100%;
            height: 50px;
            border-radius: 25px;
            background-color: #6d48ef;
            font-size: 18px;
            font-weight: 600;
            color: #ffffff;
          }
        }
      }
    }
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .signUp_step_one_wrapper {
    .signUp_step_one_inner_wrapper {
      .content_wrapper {
        width: 100%;
        .content_inner_wrapper {
          width: 100%;
          padding: 30px 15px;
        }
      }
    }
  }
}
