.userTransactionsContentComponent_wrapper {
  padding-top: 80px;
  .userTransactionsContentComponent_inner_wrapper {
    padding: 20px;
    .heading_wrapper {
      .heading_text_content {
        margin: 0;
        font-size: 28px;
        font-weight: 600;
        letter-spacing: 0.33px;
        color: #463c34;
      }
    }
    .transactions_wrapper {
      margin: 20px 0;
      .transactions_history_collection_wrapper {
        display: flex;
        flex-direction: column;
        margin: 10px 0;
        max-height: 400px;
        overflow-y: scroll;
        padding: 0 5px;
        .single_transaction_wrapper {
          border-radius: 10px;
          background-color: #ffffff;
          padding: 5px;
          margin: 10px 0;
          .sub_section_wrapper_1 {
            border-radius: 8px;
            background-color: #ffefe6;
            padding: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .left_side_content_wrapper {
              .text_content_wrapper {
                .text_content {
                  margin: 0;
                  font-size: 16px;
                  font-weight: 500;
                  color: #0f0404;
                }
              }
            }
            .right_side_content_wrapper {
              .text_content_wrapper {
                .text_content {
                  margin: 0;
                  font-size: 16px;
                  font-weight: 500;
                  color: #0f0404;
                }
              }
            }
          }
          .sub_section_wrapper_2 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            margin-top: 10px;
            padding: 10px;
            .text_content_wrapper {
              .text_content_1 {
                margin: 0;
                font-size: 14px;
                font-weight: 500;
                color: #6f6868;
              }
              .text_content_2 {
                margin: 0;
                font-size: 16px;
                font-weight: 500;
                color: #0f0404;
              }
            }
          }
          // .left_side_container {
          //   display: flex;
          //   justify-content: space-between;
          //   align-items: center;
          //   .image_container {
          //     margin: 0 10px;
          //     .image_wrapper {
          //       img {
          //       }
          //     }
          //   }
          //   .content_wrapper {
          //     .text_content_wrapper_1 {
          //       .text_content_1 {
          //         margin: 0;
          //         font-size: 20px;
          //         font-weight: 600;
          //         color: #152257;
          //       }
          //     }
          //     .text_content_wrapper_2 {
          //       .text_content_2 {
          //         margin: 0;
          //         font-size: 14px;
          //         color: #c1c3cd;
          //       }
          //     }
          //   }
          // }
          // .right_side_container {
          //   .text_content_wrapper_1 {
          //     .text_content_1 {
          //       margin: 0;
          //       font-size: 20px;
          //       font-weight: 600;
          //       color: #152257;
          //     }
          //   }
          // }
        }
      }
      .transactions_history_collection_wrapper::-webkit-scrollbar {
        width: 10px;
      }
      .transactions_history_collection_wrapper::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
      }

      /* Handle */
      .transactions_history_collection_wrapper::-webkit-scrollbar-thumb {
        background: linear-gradient(179deg, #fe9100 0%, #ff7201 100%);
        border-radius: 10px;
      }

      /* Handle on hover */
      .transactions_history_collection_wrapper::-webkit-scrollbar-thumb:hover {
        background: #3ecf7d;
      }
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .userTransactionsContentComponent_wrapper {
    padding-top: 60px;
    .userTransactionsContentComponent_inner_wrapper {
      padding: 10px;
    }
  }
}
