.headerRight_wrapper {
  .headerRight_inner_wrapper {
    // background-color: brown;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .search_bar_wrapper {
      .search_bar_inner_wrapper {
        border-radius: 25px;
        border: 2px solid #f0eef8;
        .content_wrapper {
          form {
            display: flex;
            align-items: center;
            .search_image {
              margin: 0 7px;
            }
            .search_input {
              width: 100%;
              height: 45px;
              outline: none;
              border: none;
              border-radius: 25px;
            }
            ::placeholder {
              color: #9898a0;
            }
          }
        }
      }
    }
    .notifications_wrapper {
      .notifications_inner_wrapper {
        .image_wrapper {
          img {
          }
        }
      }
    }
    .userName_and_image_wrapper {
      .userName_and_image_inner_wrapper {
        .content_wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .name_number_container {
            .text_content_wrapper_1 {
              margin: 1px 0;
              .text_content_1 {
                margin: 0;
                font-size: 16px;
                font-weight: 500;
                color: #a02b2d;
              }
            }
            .text_content_wrapper_2 {
              margin: 1px 0;
              .text_content_2 {
                margin: 0;
                font-size: 12px;
                font-weight: 500;
                color: #6f6868;
              }
            }
            .text_content_wrapper_3 {
              margin: 1px 0;
              .text_content_3 {
                margin: 0;
                font-size: 14px;
                font-weight: 600;
                color: #000000;
                img{
                  width: 15px;
                  margin-left: 2.5px;
                }
              }
            }
          }
          .image_container {
            margin: 0 7px;
            .custom_dropdown_toggle {
              .image_wrapper {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                background-color: #ffffff;
                text-align: center;
                .image_inner_wrapper {
                  display: inline-block;
                  img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                  }
                }
              }
            }
            .custom_dropdown_menu {
              border: none;
              box-shadow: 0px 46px 78px #1046752b;
              border-radius: 20px;
              .profile_option_wrapper {
                margin: 10px;
                width: 300px;
                .profile_option_inner_wrapper {
                  .content_wrapper_1 {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    border-bottom: 1px solid #7070701f;
                    .image_container {
                      margin: 5px;
                      width: 50px;
                      height: 50px;
                      border-radius: 50%;
                      background-color: #ffffff;
                      text-align: center;
                      line-height: 50px;
                      img {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                      }
                    }
                    .text_content_wrapper_1 {
                      margin: 5px;
                      .text_content_1 {
                        margin: 0;
                        color: #152257;
                        font-size: 18px;
                        font-weight: 600;
                       
                      }
                      .text_content_2 {
                        margin: 0;
                        color: #8188a5;
                        font-size: 14px;
                        opacity: 0.7;
                      }
                      .text_content_3 {
                        margin: 0;
                        color: #000000;
                        font-weight: 600;
                        font-size: 14px;
                        img{
                          width: 15px;
                          margin-left: 2.5px;
                        }
                      }
                    }
                    .text_content_wrapper_2 {
                      margin: 5px;
                      border: 1px solid #d8e5eb;
                      border-radius: 15px;
                      padding: 10px;
                      cursor: pointer;
                      .text_content_1 {
                        margin: 0;
                        color: #152257;
                        font-size: 14px;
                        font-weight: 600;
                      }
                      &:hover {
                        box-shadow: 0px 0px 8px #1046752b;
                      }
                    }
                  }
                  .content_wrapper_2 {
                    margin: 20px;
                    border-bottom: 1px solid #7070701f;
                    .text_content_wrapper_1 {
                      margin: 10px 0;
                      a {
                        text-decoration: none;
                        .text_content_1 {
                          margin: 0;
                          color: #152257;
                          font-weight: 600;
                          img {
                            width: 15px;
                            margin-right: 5px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .headerRight_wrapper {
    .headerRight_inner_wrapper {
      .search_bar_wrapper {
        .search_bar_inner_wrapper {
          .content_wrapper {
            form {
              .search_image {
              }
              .search_input {
                width: 80%;
              }
              ::placeholder {
              }
            }
          }
        }
      }
      .notifications_wrapper {
        .notifications_inner_wrapper {
          .image_wrapper {
            img {
            }
          }
        }
      }
      .userName_and_image_wrapper {
        .userName_and_image_inner_wrapper {
          .content_wrapper {
            .name_number_container {
              .text_content_wrapper_1 {
                .text_content_1 {
                }
              }
              .text_content_wrapper_2 {
                .text_content_2 {
                }
              }
            }
            .image_container {
              .custom_dropdown_toggle {
                .image_wrapper {
                  .image_inner_wrapper {
                    img {
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .headerRight_wrapper {
    .headerRight_inner_wrapper {
      .search_bar_wrapper {
        .search_bar_inner_wrapper {
          .content_wrapper {
            form {
              .search_image {
              }
              .search_input {
                width: 70%;
              }
              ::placeholder {
              }
            }
          }
        }
      }
      .notifications_wrapper {
        .notifications_inner_wrapper {
          .image_wrapper {
            img {
            }
          }
        }
      }
      .userName_and_image_wrapper {
        .userName_and_image_inner_wrapper {
          .content_wrapper {
            .name_number_container {
              .text_content_wrapper_1 {
                .text_content_1 {
                }
              }
              .text_content_wrapper_2 {
                .text_content_2 {
                }
              }
            }
            .image_container {
              .custom_dropdown_toggle {
                .image_wrapper {
                  .image_inner_wrapper {
                    img {
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
