.dashboard_slider_wrapper {
  .dashboard_slider_inner_wrapper {
    .single_image_wrapper {
      .single_image_inner_wrapper {
        img {
          border-radius: 10px;
          width: 100%;
          height: 350px;
          object-fit: fill;
        }
      }
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .dashboard_slider_wrapper {
    .dashboard_slider_inner_wrapper {
      .single_image_wrapper {
        .single_image_inner_wrapper {
          img {
            height: 220px;
          }
        }
      }
    }
  }
}
