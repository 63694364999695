////////No need to subtract scrollbar width in ipad,and mobile screens
.userDashboardContentComponent_wrapper {
  padding-top: 80px;
  .userDashboardContentComponent_inner_wrapper {
    width: 100%;
    padding: 20px;
    display: flex;
    .left_side_content_wrapper {
      width: calc(100vw - 220px - 420px - 20px - 40px);
      .left_side_content_inner_wrapper {
        padding: 0 20px;
        .slider_container {
        }
        .graphStatistics_container {
          margin-top: 20px;
        }
        .subject_collection_container {
          margin: 30px 0;
        }
      }
    }
    .right_side_content_wrapper {
      width: 420px;
      .right_side_content_inner_wrapper {
      }
    }
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .userDashboardContentComponent_wrapper {
    .userDashboardContentComponent_inner_wrapper {
      padding: 20px 10px;
      .left_side_content_wrapper {
        width: calc(100vw - 190px - 340px - 20px);
        .left_side_content_inner_wrapper {
          padding: 0 10px;
          .slider_container {
          }
          .graphStatistics_container {
            margin-top: 20px;
          }
          .subject_collection_container {
            margin: 30px 0;
          }
        }
      }
      .right_side_content_wrapper {
        width: 340px;
        .right_side_content_inner_wrapper {
        }
      }
    }
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .userDashboardContentComponent_wrapper {
    .userDashboardContentComponent_inner_wrapper {
      .left_side_content_wrapper {
        width: calc(100vw - 160px - 250px - 20px);
        .left_side_content_inner_wrapper {
          padding: 0 20px;
          .slider_container {
          }
          .graphStatistics_container {
            margin-top: 20px;
          }
          .subject_collection_container {
            margin: 30px 0;
          }
        }
      }
      .right_side_content_wrapper {
        width: 250px;
        .right_side_content_inner_wrapper {
        }
      }
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .userDashboardContentComponent_wrapper {
    padding-top: 45px;
    .userDashboardContentComponent_inner_wrapper {
      flex-wrap: wrap;
      .left_side_content_wrapper {
        order: 2;
        width: calc(100vw - 20px);
        margin: 10px 0;
        .left_side_content_inner_wrapper {
          .slider_container {
          }
          .graphStatistics_container {
          }
          .subject_collection_container {
          }
        }
      }
      .right_side_content_wrapper {
        order: 1;
        width: calc(100vw - 20px);
        margin: 10px 0;
        .right_side_content_inner_wrapper {
        }
      }
    }
  }
}
