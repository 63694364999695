.subjectCollectionView_wrapper {
  border-radius: 8px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  padding: 20px;
  .subjectCollectionView_inner_wrapper {
    .subject_collection {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .single_subject {
        flex: 0 0 30%;
        cursor: pointer;
        .image_wrapper {
          text-align: center;
          img {
          }
        }
        .text_content_wrapper {
          text-align: center;
          margin: 10px 0;
          .text_content {
            margin: 0;
            font-size: 18px;
            font-weight: 500;
            text-align: center;
            color: #0f0404;
          }
        }
      }
    }
    .viewAllTrigger_wrapper {
      margin: 10px 0;
      .btn_wrapper {
        text-align: center;
        button {
          border: none;
          outline: none;
          background: transparent;
          font-size: 14px;
          font-weight: 600;
          color: #6d48ef;
        }
      }
    }
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .subjectCollectionView_wrapper {
    .subjectCollectionView_inner_wrapper {
      .subject_collection {
        flex-wrap: wrap;
        .single_subject {
          flex: 0 0 45%;
          margin: 5px;
          .image_wrapper {
            img {
            }
          }
          .text_content_wrapper {
            .text_content {
            }
          }
        }
      }
      .viewAllTrigger_wrapper {
        .btn_wrapper {
          button {
          }
        }
      }
    }
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .subjectCollectionView_wrapper {
    .subjectCollectionView_inner_wrapper {
      .subject_collection {
        .single_subject {
          flex: 0 0 100%;
          .image_wrapper {
            img {
            }
          }
          .text_content_wrapper {
            .text_content {
            }
          }
        }
      }
      .viewAllTrigger_wrapper {
        .btn_wrapper {
          button {
          }
        }
      }
    }
  }
}
