.userBookmarksContentComponent_wrapper {
  padding-top: 80px;
  .userBookmarksContentComponent_inner_wrapper {
    padding: 20px;
    .sec_1_wrapper {
      .sec_1_inner_wrapper {
        .heading_wrapper {
          .heading_text_content {
            margin: 0;
            font-size: 28px;
            font-weight: 600;
            letter-spacing: 0.33px;
            color: #463c34;
          }
        }
      }
    }
    .sec_2_wrapper {
      margin: 20px 0;
      .sec_2_inner_wrapper {
        .sub_content_wrapper_1 {
          .bookmark_option_collection_wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            .single_select_bookmark_option {
              flex: 0 0 23%;
              input[type="radio"] {
                opacity: 0;
                position: fixed;
                width: 0;
              }
              label {
                border: solid 1px #f0eef8;
                background-color: #ffffff;
                padding: 10px 20px;
                border-radius: 10px;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.2px;
                color: #000000;
                cursor: pointer;
                width: 100%;
                text-align: center;
              }
              input[type="radio"]:checked + label {
                background-color: #6d48ef;
                border: none;
                color: #ffffff;
              }
            }
          }
        }
        .sub_content_wrapper_2 {
          margin-top: 10px;
          /////dpp questions///////////////////
          .dpp_collection_view_wrapper {
            .dpp_collection_view_innner_wrapper {
              background-color: #ffffff;
              border-radius: 10px;
              .dpp_question_collection_wrapper {
                display: flex;
                flex-direction: column;
                height: 400px;
                overflow-y: scroll;
                padding: 10px;
                .single_question_wrapper {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  border-radius: 8px;
                  background-color: #faf9fe;
                  padding: 10px;
                  margin: 10px 0;
                  cursor: pointer;
                  .left_side_content_wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    flex: 0 0 90%;
                    .serial_number_wrapper {
                      border-radius: 15px;
                      background-image: linear-gradient(
                        149deg,
                        #fff5e6 32%,
                        #ffefe6 76%
                      );
                      padding: 15px;
                      .text_content_wrapper {
                        .text_content {
                          margin: 0;
                          font-size: 20px;
                          font-weight: 600;
                          text-align: center;
                          color: #b35557;
                        }
                      }
                    }
                    .details_wrapper {
                      margin: 0 10px;
                      .text_content_wrapper {
                        p {
                          margin: 0;
                          font-size: 14px;
                          font-weight: 500;
                          color: #6f6868;
                        }
                      }
                    }
                  }
                  .right_side_content_wrapper {
                    flex: 0 0 10%;
                    display: flex;
                    justify-content: center;
                  }
                }
              }
              .dpp_question_collection_wrapper::-webkit-scrollbar {
                width: 7px;
              }
              .dpp_question_collection_wrapper::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px grey;
                border-radius: 10px;
              }

              /* Handle */
              .dpp_question_collection_wrapper::-webkit-scrollbar-thumb {
                background: #6d48ef;
                border-radius: 10px;
              }

              /* Handle on hover */
              .dpp_question_collection_wrapper::-webkit-scrollbar-thumb:hover {
                background: #6d48ef;
              }
            }
          }
          /////exercise questions///////////////////
          .exercise_collection_view_wrapper {
            .exercise_collection_view_innner_wrapper {
              background-color: #ffffff;
              border-radius: 10px;
              .exercise_question_collection_wrapper {
                display: flex;
                flex-direction: column;
                height: 400px;
                overflow-y: scroll;
                padding: 10px;
                .single_question_wrapper {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  border-radius: 8px;
                  background-color: #faf9fe;
                  padding: 10px;
                  margin: 10px 0;
                  cursor: pointer;
                  .left_side_content_wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    flex: 0 0 90%;
                    .serial_number_wrapper {
                      border-radius: 15px;
                      background-image: linear-gradient(
                        149deg,
                        #fff5e6 32%,
                        #ffefe6 76%
                      );
                      padding: 15px;
                      .text_content_wrapper {
                        .text_content {
                          margin: 0;
                          font-size: 20px;
                          font-weight: 600;
                          text-align: center;
                          color: #b35557;
                        }
                      }
                    }
                    .details_wrapper {
                      margin: 0 10px;
                      .text_content_wrapper {
                        p {
                          margin: 0;
                          font-size: 14px;
                          font-weight: 500;
                          color: #6f6868;
                        }
                      }
                    }
                  }
                  .right_side_content_wrapper {
                    flex: 0 0 10%;
                    display: flex;
                    justify-content: center;
                  }
                }
              }
              .exercise_question_collection_wrapper::-webkit-scrollbar {
                width: 7px;
              }
              .exercise_question_collection_wrapper::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px grey;
                border-radius: 10px;
              }

              /* Handle */
              .exercise_question_collection_wrapper::-webkit-scrollbar-thumb {
                background: #6d48ef;
                border-radius: 10px;
              }

              /* Handle on hover */
              .exercise_question_collection_wrapper::-webkit-scrollbar-thumb:hover {
                background: #6d48ef;
              }
            }
          }
          /////exercise questions///////////////////
          .test_collection_view_wrapper {
            .test_collection_view_innner_wrapper {
              background-color: #ffffff;
              border-radius: 10px;
              .test_question_collection_wrapper {
                display: flex;
                flex-direction: column;
                height: 400px;
                overflow-y: scroll;
                padding: 10px;
                .single_question_wrapper {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  border-radius: 8px;
                  background-color: #faf9fe;
                  padding: 10px;
                  margin: 10px 0;
                  cursor: pointer;
                  .left_side_content_wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    flex: 0 0 90%;
                    .serial_number_wrapper {
                      border-radius: 15px;
                      background-image: linear-gradient(
                        149deg,
                        #fff5e6 32%,
                        #ffefe6 76%
                      );
                      padding: 15px;
                      .text_content_wrapper {
                        .text_content {
                          margin: 0;
                          font-size: 20px;
                          font-weight: 600;
                          text-align: center;
                          color: #b35557;
                        }
                      }
                    }
                    .details_wrapper {
                      margin: 0 10px;
                      .text_content_wrapper {
                        p {
                          margin: 0;
                          font-size: 14px;
                          font-weight: 500;
                          color: #6f6868;
                        }
                      }
                    }
                  }
                  .right_side_content_wrapper {
                    flex: 0 0 10%;
                    display: flex;
                    justify-content: center;
                  }
                }
              }
              .test_question_collection_wrapper::-webkit-scrollbar {
                width: 7px;
              }
              .test_question_collection_wrapper::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px grey;
                border-radius: 10px;
              }

              /* Handle */
              .test_question_collection_wrapper::-webkit-scrollbar-thumb {
                background: #6d48ef;
                border-radius: 10px;
              }

              /* Handle on hover */
              .test_question_collection_wrapper::-webkit-scrollbar-thumb:hover {
                background: #6d48ef;
              }
            }
          }
          ///////////videos//////////////
          .video_collection_view_wrapper {
            .video_collection_view_innner_wrapper {
              background-color: #ffffff;
              border-radius: 10px;
              .videos_collection_wrapper {
                display: flex;
                flex-direction: column;
                height: 400px;
                overflow-y: scroll;
                padding: 10px;
                .single_video_wrapper {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  border-radius: 8px;
                  background-color: #faf9fe;
                  padding: 10px;
                  margin: 10px 0;
                  cursor: pointer;
                  .left_side_content_wrapper {
                    width: auto;
                    flex: auto;
                    .image_wrapper {
                      text-align: center;
                      img {
                        width: 100px;
                        border-radius: 5px;
                      }
                    }
                  }
                  .right_side_content_wrapper {
                    flex: auto;
                    margin: 0 10px;
                    width: 100%;
                    .text_content_wrapper_1 {
                      .text_content_1 {
                        margin: 0;
                        font-size: 16px;
                        font-weight: 600;
                        color: #0f0404;
                      }
                    }
                    .text_content_wrapper_2 {
                      .text_content_2 {
                        margin: 0;
                        font-size: 14px;
                        font-weight: 500;
                        color: #6f6868;
                      }
                    }
                    .sub_content_wrapper {
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      width: 100%;
                      .left_side_content_wrapper {
                        // flex: 0 0 50%;
                        .label_content_wrapper {
                          label {
                            padding: 5px 10px;
                            border-radius: 15px;
                            background-color: #fef4ec;
                            span {
                              img {
                                width: 15px;
                                height: 15px;
                              }
                            }
                          }
                        }
                      }
                      .right_side_content_wrapper {
                        // flex: 0 0 50%;
                        text-align: right;
                        margin: 0;
                        padding: 0;
                        width: auto;
                        .subject_name {
                          padding: 5px;
                          border-radius: 5px;
                          background-image: linear-gradient(
                            120deg,
                            #ff9600 37%,
                            #ff5d00 72%
                          );
                          font-size: 12px;
                          font-weight: 600;
                          color: #ffffff;
                        }
                      }
                    }
                  }
                }
              }
              .videos_collection_wrapper::-webkit-scrollbar {
                width: 7px;
              }
              .videos_collection_wrapper::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px grey;
                border-radius: 10px;
              }

              /* Handle */
              .videos_collection_wrapper::-webkit-scrollbar-thumb {
                background: #6d48ef;
                border-radius: 10px;
              }

              /* Handle on hover */
              .videos_collection_wrapper::-webkit-scrollbar-thumb:hover {
                background: #6d48ef;
              }
              .navigation_btn_wrapper {
                margin: 10px 0;
                .navigation_btn_inner_wrapper {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  .btn_wrapper {
                    button {
                      border: none;
                      outline: none;
                      width: auto;
                      height: 45px;
                      border-radius: 5px;
                      border: 2px solid #6d48ef;
                      background-color: #6d48ef;
                      font-size: 16px;
                      font-weight: 600;
                      text-align: center;
                      color: #ffffff;
                      padding: 0 10px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .userBookmarksContentComponent_wrapper {
    padding-top: 60px;
    .userBookmarksContentComponent_inner_wrapper {
      padding: 10px;
      .sec_1_wrapper {
        .sec_1_inner_wrapper {
          .heading_wrapper {
            .heading_text_content {
            }
          }
        }
      }
      .sec_2_wrapper {
        margin: 10px 0;
        .sec_2_inner_wrapper {
          .sub_content_wrapper_1 {
            .bookmark_option_collection_wrapper {
              justify-content: flex-start;
              .single_select_bookmark_option {
                margin: 2px;
                input[type="radio"] {
                }
                label {
                }
                input[type="radio"]:checked + label {
                }
              }
            }
          }
          .sub_content_wrapper_2 {
            /////dpp questions///////////////////
            .dpp_collection_view_wrapper {
              .dpp_collection_view_innner_wrapper {
                .dpp_question_collection_wrapper {
                  .single_question_wrapper {
                    .left_side_content_wrapper {
                      .serial_number_wrapper {
                        .text_content_wrapper {
                          .text_content {
                          }
                        }
                      }
                      .details_wrapper {
                        .text_content_wrapper {
                          p {
                          }
                        }
                      }
                    }
                    .right_side_content_wrapper {
                    }
                  }
                }
                .dpp_question_collection_wrapper::-webkit-scrollbar {
                }
                .dpp_question_collection_wrapper::-webkit-scrollbar-track {
                }

                /* Handle */
                .dpp_question_collection_wrapper::-webkit-scrollbar-thumb {
                }

                /* Handle on hover */
                .dpp_question_collection_wrapper::-webkit-scrollbar-thumb:hover {
                }
              }
            }
            /////exercise questions///////////////////
            .exercise_collection_view_wrapper {
              .exercise_collection_view_innner_wrapper {
                .exercise_question_collection_wrapper {
                  .single_question_wrapper {
                    .left_side_content_wrapper {
                      .serial_number_wrapper {
                        .text_content_wrapper {
                          .text_content {
                          }
                        }
                      }
                      .details_wrapper {
                        .text_content_wrapper {
                          p {
                          }
                        }
                      }
                    }
                    .right_side_content_wrapper {
                    }
                  }
                }
                .exercise_question_collection_wrapper::-webkit-scrollbar {
                }
                .exercise_question_collection_wrapper::-webkit-scrollbar-track {
                }

                /* Handle */
                .exercise_question_collection_wrapper::-webkit-scrollbar-thumb {
                }

                /* Handle on hover */
                .exercise_question_collection_wrapper::-webkit-scrollbar-thumb:hover {
                }
              }
            }
            /////exercise questions///////////////////
            .test_collection_view_wrapper {
              .test_collection_view_innner_wrapper {
                .test_question_collection_wrapper {
                  .single_question_wrapper {
                    .left_side_content_wrapper {
                      .serial_number_wrapper {
                        .text_content_wrapper {
                          .text_content {
                          }
                        }
                      }
                      .details_wrapper {
                        .text_content_wrapper {
                          p {
                          }
                        }
                      }
                    }
                    .right_side_content_wrapper {
                    }
                  }
                }
                .test_question_collection_wrapper::-webkit-scrollbar {
                }
                .test_question_collection_wrapper::-webkit-scrollbar-track {
                }

                /* Handle */
                .test_question_collection_wrapper::-webkit-scrollbar-thumb {
                }

                /* Handle on hover */
                .test_question_collection_wrapper::-webkit-scrollbar-thumb:hover {
                }
              }
            }
            ///////////videos//////////////
            .video_collection_view_wrapper {
              .video_collection_view_innner_wrapper {
                .videos_collection_wrapper {
                  .single_video_wrapper {
                    .left_side_content_wrapper {
                      .image_wrapper {
                        img {
                        }
                      }
                    }
                    .right_side_content_wrapper {
                      .text_content_wrapper_1 {
                        .text_content_1 {
                        }
                      }
                      .text_content_wrapper_2 {
                        .text_content_2 {
                        }
                      }
                      .sub_content_wrapper {
                        .left_side_content_wrapper {
                          .label_content_wrapper {
                            label {
                              span {
                                img {
                                }
                              }
                            }
                          }
                        }
                        .right_side_content_wrapper {
                          .subject_name {
                          }
                        }
                      }
                    }
                  }
                }
                .videos_collection_wrapper::-webkit-scrollbar {
                }
                .videos_collection_wrapper::-webkit-scrollbar-track {
                }

                /* Handle */
                .videos_collection_wrapper::-webkit-scrollbar-thumb {
                }

                /* Handle on hover */
                .videos_collection_wrapper::-webkit-scrollbar-thumb:hover {
                }
                .navigation_btn_wrapper {
                  .navigation_btn_inner_wrapper {
                    .btn_wrapper {
                      button {
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
