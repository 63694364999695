.test_questions_view_wrapper {
  .test_questions_view_inner_wrapper {
    .sec_1_wrapper {
      background-color: #ffffff;
      border-radius: 8px;
      padding: 20px;
      margin: 10px 0;
      .sec_1_inner_wrapper {
        .section_content_wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .left_side_content_wrapper {
            .countdown_timer_container {
              span {
                .time_number {
                  font-size: 20px;
                  font-weight: 600;
                  color: #6d48ef;
                }
                .time_partition {
                  font-size: 20px;
                  font-weight: 600;
                  color: #6d48ef;
                  margin: 3px;
                }
              }
            }
          }
          .right_side_content_wrapper {
            .btn_wrapper {
              button {
                border: none;
                outline: none;
                width: 150px;
                height: 40px;
                border-radius: 25px;
                background-color: #6d48ef;
                font-size: 18px;
                font-weight: 600;
                text-align: center;
                color: #ffffff;
              }
            }
          }
        }
      }
    }
    .sec_2_wrapper {
      padding: 20px;
      margin: 10px 0;
      .sec_2_inner_wrapper {
        .section_content_wrapper {
          display: flex;
          justify-content: space-between;
          .left_side_content_wrapper {
            flex: 0 0 28%;
            background-color: #ffffff;
            border-radius: 8px;
            padding: 10px;
            display: flex;
            flex-wrap: wrap;
            min-height: 300px;
            max-height: 500px;
            .sub_section_1_wrapper {
              flex: 0 0 100%;
              .text_content_wrapper {
                margin-bottom: 10px;
                .text_content {
                  margin: 0;
                  font-size: 16px;
                  font-weight: 600;
                  color: #0f0404;
                }
              }
              .questions_serial_number_collection {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-wrap: wrap;
                .single_question_number_wrapper {
                  margin: 5px;
                  .unanswered_lbl {
                    font-size: 15px;
                    font-weight: 500;
                    color: #686572;
                    padding: 10px;
                    border-radius: 8px;
                    background-color: #f2f2f3;
                    cursor: pointer;
                  }
                  .answered_lbl {
                    font-size: 15px;
                    font-weight: 500;
                    color: #a02b2d;
                    padding: 10px;
                    border-radius: 8px;
                    background-color: #f5e9ea;
                    cursor: pointer;
                  }
                  .review_lbl {
                    font-size: 15px;
                    font-weight: 500;
                    color: #ffffff;
                    padding: 10px;
                    border-radius: 8px;
                    background-color: #f99746;
                    cursor: pointer;
                  }
                  .skip_lbl {
                    font-size: 15px;
                    font-weight: 500;
                    color: #ffffff;
                    padding: 10px;
                    border-radius: 8px;
                    background-color: #686572;
                    cursor: pointer;
                  }
                  .current_lbl {
                    font-size: 15px;
                    font-weight: 500;
                    color: #ffffff;
                    padding: 10px;
                    background-color: #ffffff;
                    border-bottom: 2px solid #a02b2d;
                    cursor: pointer;
                  }
                }
              }
            }
            .sub_section_2_wrapper {
              flex: 0 0 100%;
              .question_marking_wrapper {
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                .single_marking {
                  margin: 5px;
                  .box_pic {
                    width: 12px;
                    height: 12px;
                    border-radius: 2px;

                    margin-right: 8px;
                  }
                  .box_pick_background_1 {
                    background-color: #a02b2d;
                  }
                  .box_pick_background_2 {
                    background-color: #f99746;
                  }
                  .box_pick_background_3 {
                    background-color: #686572;
                  }
                  .box_pick_background_4 {
                    background-color: #f2f2f3;
                  }
                  .question_marking_text {
                    font-size: 12px;
                    font-weight: 500;
                    color: #b5b3ba;
                  }
                }
              }
            }
          }
          .right_side_content_wrapper {
            flex: 0 0 68%;
            background-color: #ffffff;
            border-radius: 8px;
            padding: 10px;
            .sub_section_wrapper {
              .sub_sub_section_wrapper_1 {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .left_side_content_wrapper {
                  flex: 0 0 0;
                }
                .right_side_content_wrapper {
                  flex: 0 0 100%;
                  display: flex;
                  justify-content: flex-end;
                  .review_image_wrapper {
                    margin: 0 10px;
                    img {
                      cursor: pointer;
                    }
                  }
                  .bookmark_image_wrapper {
                    margin: 0 10px;
                    img {
                      cursor: pointer;
                    }
                  }
                }
              }
              .sub_sub_section_wrapper_2 {
                .text_content_wrapper {
                  .text_content {
                    margin: 0;
                    font-size: 18px;
                    font-weight: 500;
                    color: #09041a;
                  }
                }
              }
              .sub_sub_section_wrapper_3 {
                //single-select
                .single_select_option_collection_wrapper {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  flex-wrap: wrap;
                  .single_select {
                    flex: 0 0 45%;
                    margin: 10px;
                    input[type="checkbox"] {
                      opacity: 1;
                      position: fixed;
                      width: 0;
                    }
                    .single_option_wrapper {
                      min-height: 50px;
                      border: solid 1px #f0eef8;
                      background-color: #fafafd;
                      border-radius: 15px;
                      display: flex;
                      justify-content: flex-start;
                      align-items: center;
                      padding: 10px;
                      cursor: pointer;
                      .option_initial {
                        margin-right: 10px;
                        .text_content_2 {
                          margin: 0;
                          font-size: 16px;
                          font-weight: 500;
                          color: #6b6876;
                        }
                      }
                      .option_final {
                        .text_content_3 {
                          margin: 0;
                          font-size: 16px;
                          font-weight: 500;
                          color: #6b6876;
                        }
                      }
                    }
                    input[type="checkbox"]:checked + .single_option_wrapper {
                      border: 2px solid #6d48ef;
                      .option_initial {
                        .text_content_2 {
                          font-weight: 600;
                        }
                      }
                      .option_final {
                        .text_content_3 {
                          font-weight: 600;
                        }
                      }
                    }
                  }
                }
                //multi-select
                .multi_select_option_collection_wrapper {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  flex-wrap: wrap;
                  .single_select {
                    flex: 0 0 45%;
                    margin: 10px;
                    input[type="checkbox"] {
                      opacity: 1;
                      position: fixed;
                      width: 0;
                    }
                    .single_option_wrapper {
                      min-height: 50px;
                      border: solid 1px #f0eef8;
                      background-color: #fafafd;
                      border-radius: 15px;
                      display: flex;
                      justify-content: flex-start;
                      align-items: center;
                      padding: 10px;
                      cursor: pointer;
                      .option_initial {
                        margin-right: 10px;
                        .text_content_2 {
                          margin: 0;
                          font-size: 16px;
                          font-weight: 500;
                          color: #6b6876;
                        }
                      }
                      .option_final {
                        .text_content_3 {
                          margin: 0;
                          font-size: 16px;
                          font-weight: 500;
                          color: #6b6876;
                        }
                      }
                    }
                    input[type="checkbox"]:checked + .single_option_wrapper {
                      border: 2px solid #6d48ef;
                      .option_initial {
                        .text_content_2 {
                          font-weight: 600;
                        }
                      }
                      .option_final {
                        .text_content_3 {
                          font-weight: 600;
                        }
                      }
                    }
                  }
                }
                //integer
                .input_wrapper {
                  .input_inner_wrapper {
                    input[type="number"] {
                      border: none;
                      outline: none;
                      width: 50%;
                      border: solid 1px #f0eef8;
                      background-color: #fafafd;
                      height: 50px;
                      border-radius: 15px;
                      padding: 0 5px;
                      font-size: 16px;
                      font-weight: 500;
                    }
                    input[type="number"]:focus {
                      border: 2px solid #6d48ef;
                    }
                    input[type="number"]::-webkit-outer-spin-button,
                    input[type="number"]::-webkit-inner-spin-button {
                      -webkit-appearance: none;
                      margin: 0;
                    }
                  }
                }
              }
              .sub_sub_section_wrapper_4 {
                margin: 10px 0;
                padding: 10px;
                .checkbox_wrapper {
                  input[type="checkbox"] {
                    margin-right: 10px;
                  }
                  label {
                    font-size: 16px;
                    font-weight: 600;
                    color: #09041a;
                  }
                }
              }
              .sub_sub_section_wrapper_5 {
                box-shadow: 0 0 8px 0 rgba(109, 72, 239, 0.09);
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px;
                .left_side_wrapper {
                  .btn_wrapper {
                    text-align: left;
                    button {
                      border: none;
                      outline: none;
                      border-radius: 25px;
                      background-color: #ffffff;
                      font-size: 14px;
                      font-weight: 600;
                      text-align: center;
                      color: #686572;
                      padding: 10px;
                      width: 115px;
                      // text-decoration: underline;
                    }
                    button:hover {
                      background-color: #6d48ef;
                      color: #ffffff;
                    }
                  }
                }
                .right_side_wrapper {
                  .btn_wrapper {
                    text-align: right;
                    button {
                      border: none;
                      outline: none;
                      border-radius: 25px;
                      background-color: #ffffff;
                      font-size: 14px;
                      font-weight: 600;
                      text-align: center;
                      color: #686572;
                      padding: 10px;
                      width: 115px;
                      // text-decoration: underline;
                    }
                    button:hover {
                      background-color: #6d48ef;
                      color: #ffffff;
                    }
                  }
                }
              }
              .sub_sub_section_wrapper_6 {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .text_content_wrapper {
                  .text_content {
                    margin: 0;
                    font-size: 18px;
                    font-weight: 600;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .test_questions_view_wrapper {
    .test_questions_view_inner_wrapper {
      .sec_1_wrapper {
        .sec_1_inner_wrapper {
          .section_content_wrapper {
            .left_side_content_wrapper {
              .countdown_timer_container {
                span {
                  .time_number {
                  }
                  .time_partition {
                  }
                }
              }
            }
            .right_side_content_wrapper {
              .btn_wrapper {
                button {
                }
              }
            }
          }
        }
      }
      .sec_2_wrapper {
        .sec_2_inner_wrapper {
          .section_content_wrapper {
            .left_side_content_wrapper {
              .sub_section_1_wrapper {
                .text_content_wrapper {
                  .text_content {
                  }
                }
                .questions_serial_number_collection {
                  .single_question_number_wrapper {
                    .unanswered_lbl {
                    }
                    .answered_lbl {
                    }
                    .review_lbl {
                    }
                    .skip_lbl {
                    }
                    .current_lbl {
                    }
                  }
                }
              }
              .sub_section_2_wrapper {
                .question_marking_wrapper {
                  .single_marking {
                    .box_pic {
                    }
                    .box_pick_background_1 {
                    }
                    .box_pick_background_2 {
                    }
                    .box_pick_background_3 {
                    }
                    .box_pick_background_4 {
                    }
                    .question_marking_text {
                    }
                  }
                }
              }
            }
            .right_side_content_wrapper {
              .sub_section_wrapper {
                .sub_sub_section_wrapper_1 {
                  .left_side_content_wrapper {
                  }
                  .right_side_content_wrapper {
                    .review_image_wrapper {
                      img {
                      }
                    }
                    .bookmark_image_wrapper {
                      img {
                      }
                    }
                  }
                }
                .sub_sub_section_wrapper_2 {
                  .text_content_wrapper {
                    .text_content {
                    }
                  }
                }
                .sub_sub_section_wrapper_3 {
                  //single-select
                  .single_select_option_collection_wrapper {
                    .single_select {
                      input[type="checkbox"] {
                      }
                      .single_option_wrapper {
                        .option_initial {
                          .text_content_2 {
                          }
                        }
                        .option_final {
                          .text_content_3 {
                          }
                        }
                      }
                      input[type="checkbox"]:checked + .single_option_wrapper {
                        .option_initial {
                          .text_content_2 {
                          }
                        }
                        .option_final {
                          .text_content_3 {
                          }
                        }
                      }
                    }
                  }
                  //multi-select
                  .multi_select_option_collection_wrapper {
                    .single_select {
                      input[type="checkbox"] {
                      }
                      .single_option_wrapper {
                        .option_initial {
                          .text_content_2 {
                          }
                        }
                        .option_final {
                          .text_content_3 {
                          }
                        }
                      }
                      input[type="checkbox"]:checked + .single_option_wrapper {
                        .option_initial {
                          .text_content_2 {
                          }
                        }
                        .option_final {
                          .text_content_3 {
                          }
                        }
                      }
                    }
                  }
                  //integer
                  .input_wrapper {
                    .input_inner_wrapper {
                      input[type="number"] {
                      }
                      input[type="number"]:focus {
                      }
                      input[type="number"]::-webkit-outer-spin-button,
                      input[type="number"]::-webkit-inner-spin-button {
                      }
                    }
                  }
                }
                .sub_sub_section_wrapper_4 {
                  .checkbox_wrapper {
                    input[type="checkbox"] {
                    }
                    label {
                    }
                  }
                }
                .sub_sub_section_wrapper_5 {
                  .left_side_wrapper {
                    .btn_wrapper {
                      button {
                      }
                      button:hover {
                      }
                    }
                  }
                  .right_side_wrapper {
                    .btn_wrapper {
                      button {
                      }
                      button:hover {
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
