.userStoreContentComponent_wrapper {
  padding-top: 80px;
  .userStoreContentComponent_inner_wrapper {
    padding: 20px;
    .sec_1_wrapper {
      .text_content_wrapper {
        .text_content {
          margin: 0;
          font-size: 24px;
          font-weight: 600;
          color: #000000;
        }
      }
    }
    .sec_2_wrapper {
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .userStoreContentComponent_wrapper {
    .userStoreContentComponent_inner_wrapper {
      padding: 10px;
      .sec_1_wrapper {
        .text_content_wrapper {
          .text_content {
          }
        }
      }
      .sec_2_wrapper {
      }
    }
  }
}
