.user_edit_profile_wrapper {
  .user_edit_profile_inner_wrapper {
    background-color: #fafafb;
    display: flex;
    justify-content: flex-start;
    .user_edit_profile_left_wrapper {
      flex: 0 0 220px;
    }
    .user_edit_profile_right_wrapper {
      flex: 0 0 calc(100% - 220px);
    }
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .user_edit_profile_wrapper {
    .user_edit_profile_inner_wrapper {
      .user_edit_profile_left_wrapper {
        flex: 0 0 190px;
      }
      .user_edit_profile_right_wrapper {
        flex: 0 0 calc(100% - 190px);
      }
    }
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .user_edit_profile_wrapper {
    .user_edit_profile_inner_wrapper {
      .user_edit_profile_left_wrapper {
        flex: 0 0 160px;
      }
      .user_edit_profile_right_wrapper {
        flex: 0 0 calc(100% - 160px);
      }
    }
  }
}
