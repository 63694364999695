.termsNConditionContentComponent_wrapper {
  padding-top: 80px;
  .termsNConditionContentComponent_inner_wrapper {
    padding: 20px;
    .sec_1_wrapper {
      .sec_1_inner_wrapper {
        .heading_wrapper {
          .heading_text_content {
            margin: 0;
            font-size: 28px;
            font-weight: 600;
            letter-spacing: 0.33px;
            color: #463c34;
          }
        }
      }
    }
  }
}
