.login_step_two_wrapper {
  background-color: #fafafd;
  .login_step_two_inner_wrapper {
    height: calc(100vh - 92px);
    display: flex;
    align-items: center;
    justify-content: center;
    .content_wrapper {
      .content_inner_wrapper {
        width: 580px;
        border-radius: 8px;
        border: solid 1px #f2f2f3;
        background-color: #ffffff;
        padding: 30px 60px;
        .back_btn_wrapper {
          margin-bottom: 30px;
          button {
            border: none;
            outline: none;
            background: transparent;
          }
        }
        .image_container {
          text-align: center;
          img {
          }
        }
        .text_content_wrapper {
          margin: 10px 0;
          .text_content {
            font-size: 32px;
            font-weight: bold;
            line-height: 1.25;
            text-align: center;
            color: #a02b2d;
          }
        }
        .otp_input_wrapper {
          margin: 25px 0;
          .otp_input_inner_wrapper {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            .single_input {
              input[type="text"] {
                width: 50px;
                height: 50px;
                border-radius: 24px;
                border: solid 1px #f0eef8;
                background-color: #fafafd;
                text-align: center;
                font-size: 18px;
                font-weight: 600;
              }
            }
          }
        }
        .btn_wrapper {
          margin: 20px 0;
          text-align: center;
          button {
            border: none;
            outline: none;
            width: 100%;
            height: 50px;
            border-radius: 25px;
            background-color: #ffffff;
            box-shadow: 0px 7px 30px #6d48ef1c;
            font-size: 18px;
            font-weight: 600;
            color: #000000;
          }
          button:focus {
            background-color: #6d48ef;
            box-shadow: none;
            color: #ffffff;
          }
        }
        .resend_btn_wrapper {
          margin: 20px 0;
          text-align: center;
          button {
            border: none;
            outline: none;
            width: auto;
            height: 50px;
            border-radius: 25px;
            background-color: transparent;
            font-size: 18px;
            font-weight: 600;
            color: #6d48ef;
            text-decoration: underline;
          }
        }
      }
    }
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .login_step_two_wrapper {
    .login_step_two_inner_wrapper {
      height: calc(100vh - 58px);

      .content_wrapper {
        width: 100%;
        .content_inner_wrapper {
          width: 100%;
          padding: 30px 15px;
          .back_btn_wrapper {
            margin-bottom: 15px;
            button {
            }
          }
          .image_container {
            img {
            }
          }
          .text_content_wrapper {
            .text_content {
            }
          }
          .otp_input_wrapper {
            margin: 15px 0;
            .otp_input_inner_wrapper {
              .single_input {
                input[type="text"] {
                }
              }
            }
          }
          .btn_wrapper {
            margin: 10px 0;
            button {
            }
          }
          .resend_btn_wrapper {
            margin: 10px 0;
            button {
            }
          }
        }
      }
    }
  }
}
